<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" class="tabs">
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.BasicInformation')" name="1">
        <basicInformation :key="timer1" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.Limit')" name="2">
        <limit :key="timer2" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.password')" name="3">
        <password :key="timer3" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.wifSetting')" name="4">
        <wifSetting :key="timer4" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.position')" name="5">
        <position :key="timer5" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.bw')" name="6">
        <bw :key="timer6" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.wifiBw')" name="7">
        <wifiBw :key="timer7" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.networkAccessBW')" name="8">
        <networkAccessBW :key="timer8" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.blueToothBW')" name="9">
        <blueToothBW :key="timer9" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.networkAppBW')" name="10">
        <networkAppBW :key="timer10" :dictData="dictData" />
      </el-tab-pane>
      <!-- v6.3.2 新增 应用安装源白名单 -->
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.appInstallSourceBW')" name="11">
        <appInstallSourceBW :key="timer11" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.recentsBW')" name="12">
        <recentsBW :key="timer12" :dictData="dictData" />
      </el-tab-pane>
      <!-- 上海银行2024新增 -->
      <el-tab-pane :label="$t('DeviceManage.DevicePolicy.Tabs.smsBW')" name="13">
        <smsBW :key="timer13" :dictData="dictData"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import basicInformation from "./policyAndroid/basicInformation.vue";
import limit from "./policyAndroid/limit.vue";
import password from "./policyAndroid/password.vue";
import wifSetting from "./policyAndroid/wifSetting.vue";
import position from "./policyAndroid/position.vue";
import bw from "./policyAndroid/bw.vue";
import wifiBw from "./policyAndroid/wifiBw.vue";
import networkAccessBW from "./policyAndroid/networkAccessBW.vue";
import blueToothBW from "./policyAndroid/blueToothBW.vue";
import networkAppBW from "./policyAndroid/networkAppBW.vue";
import recentsBW from "./policyAndroid/recentsBW.vue";
import appInstallSourceBW from "./policyAndroid/appInstallSourceBW.vue";
import smsBW from "./policyAndroid/smsBW.vue";

export default {
  components: {
    basicInformation,
    limit,
    password,
    wifSetting,
    position,
    bw,
    wifiBw,
    networkAccessBW,
    blueToothBW,
    networkAppBW,
    recentsBW,
    appInstallSourceBW,
    smsBW,
  },
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      activeName: "1",
      timer1: "",
      timer2: "",
      timer3: "",
      timer4: "",
      timer5: "",
      timer6: "",
      timer7: "",
      timer8: "",
      timer9: "",
      timer10: "",
      timer11: "",
      timer12: "",
      timer13: "",
    };
  },
  watch: {
    dictData: function (newVal, oldVal) {
      if (this.dictData) {
        this.activeName = "1";
      }
    }
  },
  created() { },
  mounted() { },
  methods: {
    handleLoad(data) {
      let name = data.name;
      if (name == 1) {
        this.timer1 = new Date().getTime();
      } else if (name == 2) {
        this.timer2 = new Date().getTime();
      } else if (name == 3) {
        this.timer3 = new Date().getTime();
      } else if (name == 4) {
        this.timer4 = new Date().getTime();
      } else if (name == 5) {
        this.timer5 = new Date().getTime();
      } else if (name == 6) {
        this.timer6 = new Date().getTime();
      } else if (name == 7) {
        this.timer7 = new Date().getTime();
      } else if (name == 8) {
        this.timer8 = new Date().getTime();
      } else if (name == 9) {
        this.timer9 = new Date().getTime();
      } else if (name == 10) {
        this.timer10 = new Date().getTime();
      } else if (name == 11) {
        this.timer11 = new Date().getTime();
      } else if (name == 12) {
        this.timer12 = new Date().getTime();
      } else if (name == 13) {
        this.timer13 = new Date().getTime();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.el-form-item {
  margin-bottom: 0px;
}

.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
  height: 540px;
}

::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}

::v-deep .el-tabs__item {
  padding: 0 10px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}

::v-deep .el-tabs__item.is-active {
  color: #000;
}

::v-deep .el-tabs__item:hover {
  color: #000;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
</style>