<template>
  <div class="password">
    <el-radio-group
      v-model="dictData.configContent.bw.use"
      disabled
      style="margin-left: 150px"
    >
      <el-radio :label="'1'">{{ $t("public.Enable") }}</el-radio>
      <el-radio :label="'0'">{{ $t("public.Forbidden") }}</el-radio>
    </el-radio-group>
    <el-form :model="dictData" ref="dictData" label-width="150px">
      <el-form-item
        :label="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.ListType') +
          '：'
        "
        prop="configContent.bw.bwType"
        :required="true"
      >
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              disabled
              v-model="dictData.configContent.bw.bwType"
              style="width:240px"
            >
              <el-option
                v-for="item in bwType"
                :key="item.value"
                :label="
                  $t(
                    `DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.bwType.${item.label}`
                  )
                "
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="
          $t(
            'DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.PolicyObject'
          ) + '：'
        "
        prop="configContent.bw.target"
        :required="true"
      >
        <el-row style="line-height: 32px">
          <el-col :span="1.5">
            <el-checkbox
              style="margin-right: 30px"
              @change="Typecheck"
              v-model="checkAll"
              :label="0"
              disabled
              >{{
                $t(
                  "DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.AllApply"
                )
              }}</el-checkbox
            >
          </el-col>
          <el-col :span="6">
            <el-checkbox-group
              @change="changeRadio"
              v-model="targetArr"
              disabled
            >
              <el-checkbox
                v-model="targetArr"
                v-if="dictData.configContent.bw.bwType == 0"
                :label="2"
                >{{
                  $t(
                    "DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.NoReinforcedApply"
                  )
                }}</el-checkbox
              >
              <el-checkbox v-model="targetArr" v-else :label="1">{{
                $t(
                  "DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.ReinforcedApply"
                )
              }}</el-checkbox>
              <el-checkbox
                v-model="targetArr"
                v-if="dictData.configContent.bw.bwType == 0"
                :label="20"
                >非安全域应用</el-checkbox
              >
              <el-checkbox v-model="targetArr" v-else :label="10"
                >安全域应用</el-checkbox
              >
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="
          $t(
            'DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.ApplicationList'
          ) + '：'
        "
        prop="configContent.bw.blackWhiteList"
        :required="true"
      >
        <el-table
          :data="dictData.configContent.bw.blackWhiteList"
          lazy
          size="mini"
          stripe
          class="sort_table"
          style="width: 500px; margin-top: 5px"
          :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
        >
          <el-table-column
            width="200"
            prop="name"
            :label="$t('public.ApplicationName')"
          >
          </el-table-column>
          <el-table-column
            width="200"
            prop="identifier"
            :label="$t('public.ApplicationID')"
          >
          </el-table-column>
          <el-table-column :label="$t('public.Controls')"> </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      bwType: [
        {
          value: "0",
          label: "黑名单",
        },
        {
          value: "1",
          label: "白名单",
        },
      ],
      checkAll: false,
      targetArr: [],
      bw: {
        use: "0",
        bwType: "0",
        target: [],
        blackWhiteList: [],
      },
    };
  },
  created() {
    // if (this.dictData.configContent.bw.use == 0) {
    //   this.dictData.configContent.bw.bwType = this.$t(
    //     "Delivery.DistributionHistory.BlackList"
    //   );
    // }
    if (this.dictData.id) {
      this.bw = this.dictData.configContent.bw;
      if (this.bw.target) {
        let checkdet = this.bw.target.split(",").map(Number);
        this.targetArr = checkdet;
        if (this.targetArr[0] == 0) {
          this.checkAll = true;
        }
      }
      if (!this.dictData.configContent.bw.target) {
        this.$set(this.bw, "target", []);
      }
      if (this.dictData.configContent.bw.blackWhiteList == undefined) {
        this.bw.blackWhiteList = [];
      }
    }
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      if (this.dictData.configContent.bw.use == 0) {
        this.dictData.configContent.bw.bwType = this.$t(
          "Delivery.DistributionHistory.BlackList"
        );
      }
    },
    changeRadio() {},
    // v6.1.1 多选
    Typecheck() {},
  },
};
</script>

<style lang="scss" scoped>
.password {
  padding-top: 20px;
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
}

::v-deep .el-checkbox__label {
  font-size: 12px !important;
}

::v-deep .el-table__empty-block {
  width: 34% !important;
}

.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  /* line-height: 24px; */
  color: #807e7e;
  display: block;
}

::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is-disabled + span.el-radio__label {
  color: #807e7e;
}

::v-deep .el-form-item__content {
  font-size: 12px;
  line-height: 0px;
  margin-top: 5px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>