<template>
  <div class="password">
    <el-radio-group
      v-model="dictData.configContent.bw.use"
      disabled
      style="margin-left: 150px"
    >
      <el-radio :label="'1'">{{ $t("public.Enable") }}</el-radio>
      <el-radio :label="'0'">{{ $t("public.Forbidden") }}</el-radio>
    </el-radio-group>
    <el-form :model="dictData" ref="dictData" label-width="150px">
      <el-form-item
        :label="$t('DeviceManage.ClientPolicy.BlackWhiteList.ListType')"
        prop="configContent.bw.type"
        :required="true"
      >
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              disabled
              v-model="dictData.configContent.bw.type"
            >
              <el-option
                v-for="item in bwType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.ClientPolicy.BlackWhiteList.Target')"
        prop="configContent.bw.target"
        :required="true"
      >
        <div style="flot: left; display: flex; align-items: center">
          <span>
            <el-checkbox
              style="margin-right: 30px"
              @change="Typecheck"
              v-model="checkAll"
              :label="0"
              disabled
              >{{
                $t(
                  "DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.AllApply"
                )
              }}</el-checkbox
            >
          </span>
          <span>
            <el-checkbox-group
              disabled
              style="margin-top: 0px"
              @change="changeRadio"
              v-model="targetArr"
            >
              <el-checkbox v-if="valueSelect == 0" :label="2">{{
                $t("DeviceManage.ClientPolicy.BlackWhiteList.NonReinforcedApps")
              }}</el-checkbox>
              <el-checkbox v-else :label="1">{{
                $t("DeviceManage.ClientPolicy.BlackWhiteList.ReinforcedApps")
              }}</el-checkbox>
              <el-checkbox v-if="valueSelect == 0" :label="20"
                >非安全域应用</el-checkbox
              >
              <el-checkbox v-else :label="10">安全域应用</el-checkbox>
            </el-checkbox-group>
          </span>
        </div>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.ClientPolicy.BlackWhiteList.AppList')"
        prop="configContent.bw.list"
        :required="true"
      >
        <el-table
          :data="dictData.configContent.bw.list"
          lazy
          size="mini"
          stripe
          class="sort_table"
          style="width: 500px; margin-top: 5px"
          :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
        >
          <el-table-column
            width="200"
            prop="name"
            :label="$t('public.ApplicationName')"
          >
          </el-table-column>
          <el-table-column
            prop="identifier"
            :label="$t('public.ApplicationID')"
            width="160"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column :label="$t('public.Controls')"> </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.ClientPolicy.TriggeringCondition')"
      >
        <span v-if="dictData.configContent.bw.type == 1">{{
          $t("DeviceManage.ClientPolicy.BlackWhiteList.Message2")
        }}</span>
        <span
          v-if="
            dictData.configContent.bw.type == 0 ||
            dictData.configContent.bw.use == '0'
          "
          >{{ $t("DeviceManage.ClientPolicy.BlackWhiteList.Message1") }}</span
        >
      </el-form-item>
      <el-form-item
        :label="$t('DeviceManage.ClientPolicy.ResponseAction')"
        :required="true"
      >
        <el-table
          :data="dictData.configContent.bw.content"
          lazy
          size="mini"
          stripe
          class="sort_table"
          style="width: 501px; margin-top: 5px"
          :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
        >
          <el-table-column
            width="60"
            type="index"
            :label="$t('DeviceManage.ClientPolicy.SerialNumber')"
          >
          </el-table-column>
          <el-table-column
            width="259"
            prop="name"
            :label="$t('DeviceManage.ClientPolicy.ResponseAction1')"
          >
            <template v-slot="{ row }">
              <template
                v-if="row.operType == 0 || dictData.configContent.bw.use == '0'"
              >
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName0") }}
              </template>
              <template v-if="row.operType == 1">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName1") }}
              </template>
              <template v-if="row.operType == 2">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName2") }}
              </template>
              <template v-if="row.operType == 3">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName3") }}
              </template>
              <template v-if="row.operType == 4">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName4") }}
              </template>
              <template v-if="row.operType == 5">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName5") }}
              </template>
              <template v-if="row.operType == 6">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName6") }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            prop="name"
            :label="$t('DeviceManage.ClientPolicy.LayTime1')"
          >
            <template v-slot="{ row }">
              <template>{{ row.frequency }}</template>
              <template>
                <span v-if="row.frequencyType == 'hour'">{{
                  $t("DeviceManage.ClientPolicy.Hour")
                }}</span>
                <span v-if="row.frequencyType == 'min'">{{
                  $t("DeviceManage.ClientPolicy.Min")
                }}</span>
                <span v-if="row.frequencyType == 'day'">{{
                  $t("DeviceManage.ClientPolicy.Day")
                }}</span>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      checkList: [
        {
          value: "bluetooth",
          label: "蓝牙",
        },
        {
          value: "wifi",
          label: "WIFI网络",
        },
        {
          value: "dataLink",
          label: "移动网络",
        },

        {
          value: "hotSpot",
          label: "个人热点",
        },
        {
          value: "location",
          label: "系统定位",
        },
      ],
      bwType: [
        {
          value: "0",
          label: this.$t("DeviceManage.ClientPolicy.BlackWhiteList.BlackList"),
        },
        {
          value: "1",
          label: this.$t("DeviceManage.ClientPolicy.BlackWhiteList.WhiteList"),
        },
      ],
      content: [
        {
          frequency: "0",
          frequencyType: "min",
          operType: "0",
        },
      ],
      valueSelect: "0",
    };
  },
  created() {
    if (this.dictData.configContent.bw.use == 0) {
      this.dictData.configContent.bw.content = this.content;
      this.dictData.configContent.bw.type = this.$t(
        "DeviceManage.ClientPolicy.BlackWhiteList.BlackList"
      );
    }
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      if (this.dictData.configContent.bw.use == 0) {
        this.dictData.configContent.bw.type = this.$t(
          "DeviceManage.ClientPolicy.BlackWhiteList.BlackList"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  padding-top: 20px;
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
}

::v-deep .el-table__empty-block {
  width: 34% !important;
}

.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  /* line-height: 24px; */
  color: #807e7e;
  display: block;
}

::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is-disabled + span.el-radio__label {
  color: #807e7e;
}

::v-deep .el-form-item__content {
  font-size: 12px;
}

::v-deep .el-table__row > td {
  border: none;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
