<template>
  <div class="password">
    <el-radio-group v-model="dictData.configContent.smsBW.use" disabled>
      <el-radio :label="'1'">{{ $t("public.Enable") }}</el-radio>
      <el-radio :label="'0'">{{ $t("public.Forbidden") }}</el-radio>
    </el-radio-group>
    <el-form :model="dictData" label-width="150px">
      <el-form-item
        :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.ListType') + '：'"
      >
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              style="width: 240px"
              v-model="dictData.configContent.smsBW.type"
              disabled
            >
              <el-option
                v-for="item in type"
                :key="item.value"
                :label="
                  $t(
                    `DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.RecentsType.${item.label}`
                  )
                "
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.RecentsList') + '：'
        "
        :required="true"
      >
        <el-row>
          <el-col :span="3.5">
            <el-input
              style="margin-top: 5px; width: 240px"
              size="small"
              v-model="dictData.configContent.smsBW.phones"
              type="textarea"
              :rows="4"
              disabled
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint" style="margin-left: 8px; color: #807e7e">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.macStrTetx')}}</span>
            <!-- <br /> -->
            <span class="hint" style="margin-left: 8px; color: #807e7e">{{
              $t("DeviceManage.DevicePolicy.AndroidPolicy.recentsBW.macStrText1")
            }}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      smsBW: {
        use: "0",
        type: "0",
        phones: "",
      },
      type: [
        {
          value: "0",
          label: "黑名单",
        },
        {
          value: "1",
          label: "白名单",
        },
      ],
    };
  },
  created() {
      if (this.dictData.configContent.smsBW.use == 0) {
        this.dictData.configContent.smsBW.type = this.$t('Delivery.DistributionHistory.BlackList');
      }
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      if (this.dictData.configContent.smsBW.use == 0) {
        this.dictData.configContent.smsBW.type = this.$t('Delivery.DistributionHistory.BlackList');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
}
.hint {
  font-size: 12px;
  // margin-left: 8px;
  height: 24px;
  /* line-height: 24px; */
  color: #807e7e;
  display: block;
}
::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is-disabled+span.el-radio__label {
  color: #807e7e;
}

::v-deep .el-form-item__content {
  font-size: 12px;
}
</style>