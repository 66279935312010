<template>
  <div class="password">
    <el-radio-group v-model="dictData.configContent.systemFunctions.use" disabled>
      <el-radio :label="'1'">{{ $t('public.Enable') }}</el-radio>
      <el-radio :label="'0'">{{ $t('public.Forbidden') }}</el-radio>
    </el-radio-group>
    <el-form :model="dictData" ref="dictData" label-width="150px">
      <el-form-item :label="$t('DeviceManage.ClientPolicy.SystemFunction.ConnectionType')" prop="name" :required="true">
        <el-row>
          <el-col :span="12">
            <el-checkbox-group v-model="dictData.configContent.systemFunctions.functionlist">
              <el-checkbox disabled v-for="(item, index) in checkList" :key="index" :label="item.value">{{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.ClientPolicy.TriggeringCondition')">
        {{ $t('DeviceManage.ClientPolicy.SystemFunction.Message1') }}
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.ClientPolicy.ResponseAction')" :required="true">
        <el-table :data="dictData.configContent.systemFunctions.content" lazy size="mini" stripe class="sort_table"
          style="width: 501px; margin-top: 5px" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }">
          <el-table-column width="60" type="index" :label="$t('DeviceManage.ClientPolicy.SerialNumber')">
          </el-table-column>
          <el-table-column width="259" prop="name" :label="$t('DeviceManage.ClientPolicy.ResponseAction1')">
            <template v-slot="{ row }">
              <template v-if="row.operType == 0">
                {{ $t('DeviceManage.ClientPolicy.ResponseActionName0') }}
              </template>
              <template v-if="row.operType == 1"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName1') }} </template>
              <template v-if="row.operType == 2"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName2') }} </template>
              <template v-if="row.operType == 3"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName3') }} </template>
              <template v-if="row.operType == 4"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName4') }} </template>
              <template v-if="row.operType == 5"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName5') }} </template>
              <template v-if="row.operType == 6"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName6') }} </template>
            </template>
          </el-table-column>
          <el-table-column width="180" prop="name" :label="$t('DeviceManage.ClientPolicy.LayTime1')">
            <template v-slot="{ row }">
              <template>{{ row.frequency }}</template>
              <template>
                <span v-if="row.frequencyType == 'hour'">{{ $t('DeviceManage.ClientPolicy.Hour') }}</span>
                <span v-if="row.frequencyType == 'min'">{{ $t('DeviceManage.ClientPolicy.Min') }}</span>
                <span v-if="row.frequencyType == 'day'">{{ $t('DeviceManage.ClientPolicy.Day') }}</span>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      checkList: [
        {
          value: 'bluetooth',
          label: this.$t('DeviceManage.ClientPolicy.SystemFunction.bluetooth')
        },
        {
          value: 'wifi',
          label: this.$t('DeviceManage.ClientPolicy.SystemFunction.wifi')
        },
        {
          value: 'dataLink',
          label: this.$t('DeviceManage.ClientPolicy.SystemFunction.dataLink')
        },

        {
          value: 'hotSpot',
          label: this.$t('DeviceManage.ClientPolicy.SystemFunction.hotspot')
        },
        {
          value: 'location',
          label: this.$t('DeviceManage.ClientPolicy.SystemFunction.location')
        }
      ],
      content: [
        {
          frequency: '0',
          frequencyType: 'min',
          operType: '0'
        }
      ]
    }
  },
  created() {
    if (this.dictData.configContent.systemFunctions.use == 0) {
      this.dictData.configContent.systemFunctions.content = this.content
    }
  },
  mounted() { },
  methods: {}
}
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}

.el-radio-group {
  margin-left: 150px;
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
}

.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  color: #807e7e;
  display: block;
}

::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is-disabled+span.el-radio__label {
  color: #807e7e;
}

::v-deep .el-form-item__content {
  font-size: 12px;
}

::v-deep .el-table__empty-block {
  width: 20% !important;
}

::v-deep .el-table__row>td {
  border: none;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table th.is-leaf {
  border: none;
}</style>