<template>
  <div>
    <el-form
      :model="dictData"
      ref="dictData"
      label-width="150px"
    >
      <el-form-item :label="$t('Delivery.DistributionHistory.NoticeTitle')+'：'" prop="title">
        {{ dictData.title }}
      </el-form-item>
      <el-form-item :label="$t('Delivery.DistributionHistory.NoticeContent')+'：'" prop="content">
        <el-input
          type="textarea"
          :rows="4"
          v-model="dictData.content"
          disabled
          size="mini"
          style="width: 90%;margin-top:10px"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.el-form-item {
  margin-bottom: 0px;
}
</style>
