<template>
  <div class="password">
    <el-radio-group v-model="dictData.configContent.networkAppBW.use" disabled>
      <el-radio :label="'1'">{{ $t("public.Enable") }}</el-radio>
      <el-radio :label="'0'">{{ $t("public.Forbidden") }}</el-radio>
    </el-radio-group>
    <el-form :model="dictData" ref="dictData" label-width="150px">
      <el-form-item
        :label="
          $t('DeviceManage.DevicePolicy.AndroidPolicy.networkAppBW.ListType') +
          '：'
        "
        prop="configContent.networkAppBW.bwType"
      >
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              disabled
              v-model="dictData.configContent.networkAppBW.bwType"
              style="width:240px"
            >
              <el-option
                v-for="item in bwType"
                :key="item.value"
                :label="
                  $t(
                    `DeviceManage.DevicePolicy.AndroidPolicy.networkAppBW.bwType.${item.label}`
                  )
                "
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="
          $t(
            'DeviceManage.DevicePolicy.AndroidPolicy.networkAppBW.ApplicationList'
          ) + '：'
        "
        prop="configContent.networkAppBW.list"
        :required="true"
      >
        <el-table
          :data="dictData.configContent.networkAppBW.list"
          lazy
          size="mini"
          stripe
          class="sort_table"
          style="width: 500px; margin-top: 5px"
          :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
        >
          <el-table-column
            width="200"
            prop="name"
            :label="$t('public.ApplicationName')"
          >
          </el-table-column>
          <el-table-column  prop="identifier"  :label="$t('public.ApplicationID')"  width="160"  show-overflow-tooltip>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')"> </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      bwType: [
        {
          value: "0",
          label: "黑名单",
        },
        {
          value: "1",
          label: "白名单",
        },
      ],
    };
  },
  created() {
    console.log(this.dictData.configContent,'this.dictDatathis.dictData');
    if (this.dictData.configContent.networkAppBW.use == 0) {
      this.dictData.configContent.networkAppBW.bwType = this.$t(
        "Delivery.DistributionHistory.WhiteList"
      );
    }
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      if (this.dictData.configContent.networkAppBW.use == 0) {
        this.dictData.configContent.networkAppBW.bwType = this.$t(
          "Delivery.DistributionHistory.WhiteList"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  padding-top: 20px;
}

.el-radio-group {
  margin-left: 150px;
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
}

::v-deep .el-table__empty-block {
  width: 34% !important;
}

.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  /* line-height: 24px; */
  color: #807e7e;
  display: block;
}

::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is-disabled + span.el-radio__label {
  color: #807e7e;
}

::v-deep .el-form-item__content {
  font-size: 12px;
  line-height: 0px;
  margin-top: 5px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>