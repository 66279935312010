<template>
  <div class="password">
    <el-radio-group v-model="dictData.configContent.password.use" disabled>
      <el-radio :label="'1'">{{ $t('public.Enable') }}</el-radio>
      <el-radio :label="'0'">{{ $t('public.Forbidden') }}</el-radio>
    </el-radio-group>
    <el-form :model="dictData" ref="dictData" label-width="180px">
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SimplePassword') + '：'"
        prop="configContent.password.allowSimple">
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.PasswordComplexity')"
          v-model="dictData.configContent.password.allowSimple" true-label="1" false-label="0" disabled></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.PasswordLength') + '：'"
        prop="configContent.password.requireAlphaNumber">
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.RequireAlphaNumber')"
          v-model="dictData.configContent.password.requireAlphaNumber" true-label="1" false-label="0"
          disabled></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.DigitalPassword') + '：'"
        prop="configContent.password.forcePin">
        <el-checkbox v-model="dictData.configContent.password.forcePin" true-label="1" false-label="0"
          disabled></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.MinLengthPassword') + '：'"
        prop="configContent.password.minLength">
        <el-row>
          <el-col :span="6">
            <el-select disabled size="small" v-model="dictData.configContent.password.minLength">
              <el-option v-for="item in minLength" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{ $t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SpanTextMinLength') }}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.MinComplexChars') + '：'"
        prop="configContent.password.minComplexChars">
        <el-row>
          <el-col :span="6">
            <el-select disabled size="small" v-model="dictData.configContent.password.minComplexChars">
              <el-option v-for="item in minComplexChars" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{ $t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SpanText') }}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.MaxPinAgeinDays') + '：'"
        prop="configContent.password.maxPinAgeinDays" :required="true">
        <el-row>
          <el-col :span="6">
            <el-input size="small" v-model="dictData.configContent.password.maxPinAgeinDays" disabled></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{ $t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SpanTextMax') }}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.PinHistory') + '：'"
        prop="configContent.password.pinHistory" :required="true">
        <el-row>
          <el-col :span="6">
            <el-input size="small" v-model.trim="dictData.configContent.password.pinHistory" disabled></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{ $t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SpanTextStory') }}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.MaxFailedTimes') + '：'"
        prop="configContent.password.maxFailedTimes">
        <el-row>
          <el-col :span="6">
            <el-select disabled size="small" v-model="dictData.configContent.password.maxFailedTimes">
              <el-option v-for="item in maxFailedTimes" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{ $t('DeviceManage.DevicePolicy.IOSPolicy.PasswordSettings.SpanTextTimes') }}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      minLength: [
        {
          value: "6",
          label: "6",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "8",
          label: "8",
        },
        {
          value: "9",
          label: "9",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "11",
          label: "11",
        },
        {
          value: "12",
          label: "12",
        },
        {
          value: "13",
          label: "13",
        },
        {
          value: "14",
          label: "14",
        },
        {
          value: "15",
          label: "15",
        },
        {
          value: "16",
          label: "16",
        },
      ],
      minComplexChars: [
        {
          value: "0",
          label: "0",
        },
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
      ],
      maxFailedTimes: [
        {
          value: "",
          label: "请选择",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "6",
          label: "6",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "8",
          label: "8",
        },
        {
          value: "9",
          label: "9",
        },
        {
          value: "10",
          label: "10",
        },
      ],
    };
  },
  created() { },
  mounted() { },
  methods: {},
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}

.el-radio-group {
  margin-left: 180px;
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}

.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  /* line-height: 24px; */
  color: #807e7e;
  display: block;
}

::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is-disabled+span.el-radio__label {
  color: #807e7e;
}

::v-deep .el-form-item__content {
  font-size: 12px;
}
</style>