var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"password"},[_c('el-radio-group',{staticStyle:{"margin-left":"150px"},attrs:{"disabled":""},model:{value:(_vm.dictData.configContent.bw.use),callback:function ($$v) {_vm.$set(_vm.dictData.configContent.bw, "use", $$v)},expression:"dictData.configContent.bw.use"}},[_c('el-radio',{attrs:{"label":'1'}},[_vm._v(_vm._s(_vm.$t("public.Enable")))]),_c('el-radio',{attrs:{"label":'0'}},[_vm._v(_vm._s(_vm.$t("public.Forbidden")))])],1),_c('el-form',{ref:"dictData",attrs:{"model":_vm.dictData,"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":_vm.$t('DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.ListType') +
        '：',"prop":"configContent.bw.bwType","required":true}},[_c('el-row',[_c('el-col',{attrs:{"span":6}},[_c('el-select',{staticStyle:{"width":"240px"},attrs:{"size":"small","disabled":""},model:{value:(_vm.dictData.configContent.bw.bwType),callback:function ($$v) {_vm.$set(_vm.dictData.configContent.bw, "bwType", $$v)},expression:"dictData.configContent.bw.bwType"}},_vm._l((_vm.bwType),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$t(
                  `DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.bwType.${item.label}`
                ),"value":item.value}})}),1)],1)],1)],1),_c('el-form-item',{attrs:{"label":_vm.$t(
          'DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.PolicyObject'
        ) + '：',"prop":"configContent.bw.target","required":true}},[_c('el-row',{staticStyle:{"line-height":"32px"}},[_c('el-col',{attrs:{"span":1.5}},[_c('el-checkbox',{staticStyle:{"margin-right":"30px"},attrs:{"label":0,"disabled":""},on:{"change":_vm.Typecheck},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v(_vm._s(_vm.$t( "DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.AllApply" )))])],1),_c('el-col',{attrs:{"span":6}},[_c('el-checkbox-group',{attrs:{"disabled":""},on:{"change":_vm.changeRadio},model:{value:(_vm.targetArr),callback:function ($$v) {_vm.targetArr=$$v},expression:"targetArr"}},[(_vm.dictData.configContent.bw.bwType == 0)?_c('el-checkbox',{attrs:{"label":2},model:{value:(_vm.targetArr),callback:function ($$v) {_vm.targetArr=$$v},expression:"targetArr"}},[_vm._v(_vm._s(_vm.$t( "DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.NoReinforcedApply" )))]):_c('el-checkbox',{attrs:{"label":1},model:{value:(_vm.targetArr),callback:function ($$v) {_vm.targetArr=$$v},expression:"targetArr"}},[_vm._v(_vm._s(_vm.$t( "DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.ReinforcedApply" )))]),(_vm.dictData.configContent.bw.bwType == 0)?_c('el-checkbox',{attrs:{"label":20},model:{value:(_vm.targetArr),callback:function ($$v) {_vm.targetArr=$$v},expression:"targetArr"}},[_vm._v("非安全域应用")]):_c('el-checkbox',{attrs:{"label":10},model:{value:(_vm.targetArr),callback:function ($$v) {_vm.targetArr=$$v},expression:"targetArr"}},[_vm._v("安全域应用")])],1)],1)],1)],1),_c('el-form-item',{attrs:{"label":_vm.$t(
          'DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.ApplicationList'
        ) + '：',"prop":"configContent.bw.blackWhiteList","required":true}},[_c('el-table',{staticClass:"sort_table",staticStyle:{"width":"500px","margin-top":"5px"},attrs:{"data":_vm.dictData.configContent.bw.blackWhiteList,"lazy":"","size":"mini","stripe":"","header-cell-style":{ background: '#f7f8fa', color: '#606266' }}},[_c('el-table-column',{attrs:{"width":"200","prop":"name","label":_vm.$t('public.ApplicationName')}}),_c('el-table-column',{attrs:{"width":"200","prop":"identifier","label":_vm.$t('public.ApplicationID')}}),_c('el-table-column',{attrs:{"label":_vm.$t('public.Controls')}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }