<template>
  <div>
    <el-form :model="dictData" ref="dictData" label-width="150px">
      <el-form-item :label="$t('AppManage.ThreatDefinition.SelectFile')+'：'" prop="name">
        <el-input
          v-model="dictData.name"
          disabled
          style="width: 90%"
        ></el-input>
      </el-form-item>
      <!-- 类别 -->
      <el-form-item :label="$t('ContentManage.DocumentManagement.CategoryFullName')+'：'" prop="categoryFullName">
        <el-select
          v-model="dictData.categoryFullName"
          disabled
          style="width: 90%; margin-top: 6px"
          placeholder=""
        >
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('ContentManage.DocumentManagement.DeleteOnReaded')+'：'" prop="isBurnAfterRead">
        <el-select
          v-model="dictData.isBurnAfterRead"
          disabled
          style="width: 90%; margin-top: 6px"
          placeholder=""
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="$t(`ContentManage.DocumentManagement.isBurnAfterRead.${item.label}`)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('ContentManage.DocumentManagement.DocIntro')+'：'" prop="memo">
        <el-input
          type="textarea"
          :rows="4"
          v-model="dictData.memo"
          disabled
          size="mini"
          style="width: 90%; margin-top: 6px"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      options2: [
        { label: "", value: -1 },
        { label: "是", value: 1 },
        { label: "否", value: 0 },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.el-form-item {
  margin-bottom: 0px;
}
</style>
