<template>
  <div class="password">
    <el-radio-group v-model="dictData.configContent.wifi.use" disabled>
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="dictData" ref="dictData" label-width="150px">
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.lineType')+'：'" prop="configContent.wifi.wifiConnType">
        <el-row>
          <el-col :span="6">
            <el-select size="small" disabled v-model="dictData.configContent.wifi.wifiConnType" style="width:240px">
              <el-option v-for="item in wifiConnType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.WiFiName')+'：'" prop="configContent.wifi.ssidStr" :required="true" v-if="
        dictData.configContent.wifi.wifiConnType == 0 ||
        dictData.configContent.wifi.use == 0
      ">
        <el-row>
          <el-col :span="6">
            <el-input size="small" v-model="dictData.configContent.wifi.ssidStr" disabled style="width:240px"></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.SpanText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.MACPosition')+'：'" prop="configContent.wifi.macStr" :required="true"
        v-if="dictData.configContent.wifi.wifiConnType == 1">
        <el-row>
          <el-col :span="6">
            <el-input size="small" v-model="dictData.configContent.wifi.macStr" disabled style="width:240px"></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.SpanTextMac')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.HideNetwork')+'：'" prop="configContent.wifi.hiddenNetwork">
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.NetworkText')" v-model="dictData.configContent.wifi.hiddenNetwork" true-label="1"
          false-label="0" disabled></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.Automatic')+'：'" prop="configContent.wifi.autoJoin">
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.AutomaticText')" v-model="dictData.configContent.wifi.autoJoin" true-label="1" false-label="0"
          disabled></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.SecurityType')+'：'" prop="configContent.wifi.encryptionType">
        <el-row>
          <el-col :span="6">
            <el-select size="small" disabled v-model="dictData.configContent.wifi.encryptionType" style="width:240px">
              <el-option v-for="item in encryptionType" :key="item.value" :label="$t(`DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.encryptionType.${item.label}`)" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.SecurityText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.Password')+'：'" prop="configContent.wifi.password">
        <el-row>
          <el-col :span="6">
            <el-input size="small" v-model="dictData.configContent.wifi.password" disabled style="width:240px"></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.WiFiSetup.PasswordText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      wifiConnType: [
        {
          value: "0",
          label: "SSID",
        },
        {
          value: "1",
          label: "BSSID",
        },
      ],
      encryptionType: [
        {
          value: "None",
          label: "无",
        },
        {
          value: "WPA",
          label: "WPA / WPA2",
        },
        {
          value: "WEP",
          label: "WEP",
        },
      ],
    };
  },
  created() {
    if (this.dictData.configContent.wifi.use == 0) {
      this.dictData.configContent.wifi.wifiConnType = "SSID";
      this.dictData.configContent.wifi.encryptionType = this.$t('Delivery.DistributionHistory.Null');
    }
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      if (this.dictData.configContent.wifi.use == 0) {
        this.dictData.configContent.wifi.wifiConnType = "SSID";
        this.dictData.configContent.wifi.encryptionType = this.$t('Delivery.DistributionHistory.Null');
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}

.el-radio-group {
  margin-left: 150px;
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
}

.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  /* line-height: 24px; */
  color: #807e7e;
  display: block;
}

::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is-disabled+span.el-radio__label {
  color: #807e7e;
}

::v-deep .el-form-item__content {
  font-size: 12px;
}
</style>