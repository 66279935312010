<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" class="tabs">
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.BasicInformation')" name="1">
        <basicInformation :key="timer1" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.Equipment')" name="2">
        <root :key="timer2" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.Geographical')" name="3">
        <geofence :key="timer3" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.SystemVersion')" name="4">
        <version :key="timer4" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.DeviceBattery')" name="5">
        <battery :key="timer5" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.StorageSpace')" name="6">
        <capacity :key="timer6" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.SystemFunction')" name="7">
        <systemFunctions :key="timer7" :dictData="dictData" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import basicInformation from "./clientIOS/basicInformation.vue";
import capacity from "./clientIOS/capacity.vue";
import root from "./clientIOS/root.vue";
import geofence from "./clientIOS/geofence.vue";
import version from "./clientIOS/version.vue";
import battery from "./clientIOS/battery.vue";
import systemFunctions from "./clientIOS/systemFunctions.vue";
export default {
  components: {
    basicInformation,
    capacity,
    root,
    geofence,
    version,
    battery,
    systemFunctions,
  },
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      activeName: "1",
      timer1: "",
      timer2: "",
      timer3: "",
      timer4: "",
      timer5: "",
      timer6: "",
      timer7: "",
    };
  },
  watch: {
    dictData: function (newVal, oldVal) {
      if (this.dictData) {
        this.activeName = "1";
      }
    }
  },
  created() { },
  mounted() { },
  methods: {
    handleLoad(data) {
      let name = data.name;
      if (name == 1) {
        this.timer1 = new Date().getTime();
      } else if (name == 2) {
        this.timer2 = new Date().getTime();
      } else if (name == 3) {
        this.timer3 = new Date().getTime();
      } else if (name == 4) {
        this.timer4 = new Date().getTime();
      } else if (name == 5) {
        this.timer5 = new Date().getTime();
      } else if (name == 6) {
        this.timer6 = new Date().getTime();
      } else if (name == 7) {
        this.timer7 = new Date().getTime();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.el-form-item {
  margin-bottom: 0px;
}

.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
  height: 540px;
}

::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}

::v-deep .el-tabs__item {
  padding: 0 10px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}

::v-deep .el-tabs__item.is-active {
  color: #000;
}

::v-deep .el-tabs__item:hover {
  color: #000;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
</style>