<template>
  <div class="password">
    <el-radio-group v-model="dictData.configContent.wifi.use" disabled>
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="dictData" ref="dictData" label-width="150px">
      <el-form-item
      :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.lineType')+'：'"
        prop="configContent.wifi.wifiConnType"
        :required="true"
      >
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              disabled
              v-model="dictData.configContent.wifi.wifiConnType"
            >
              <el-option
                v-for="item in wifiConnType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
      :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.WiFiName')+'：'"
        prop="configContent.wifi.ssidStr"
        :required="true"
        v-if="dictData.configContent.wifi.wifiConnType == 0"
      >
        <el-row>
          <el-col :span="6">
            <el-input
              size="small"
              v-model="dictData.configContent.wifi.ssidStr"
              disabled
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
      :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.MACPosition')+'：'"
        prop="configContent.wifi.macStr"
        :required="true"
        v-if="dictData.configContent.wifi.wifiConnType == 1"
      >
        <el-row>
          <el-col :span="6">
            <el-input
              size="small"
              v-model="dictData.configContent.wifi.macStr"
              disabled
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanTextMac')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.HideNetwork')+'：'" prop="configContent.wifi.hiddenNetwork">
        <el-checkbox
        :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.NetworkText')"
          v-model="dictData.configContent.wifi.hiddenNetwork"
          true-label="1"
          false-label="0"
          disabled
        ></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.Automatic')+'：'" prop="configContent.wifi.autoJoin">
        <el-checkbox
        :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.AutomaticText')"
          v-model="dictData.configContent.wifi.autoJoin"
          true-label="1"
          false-label="0"
          disabled
        ></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SecurityType')+'：'" prop="configContent.wifi.encryptionType">
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              disabled
              v-model="dictData.configContent.wifi.encryptionType"
            >
              <el-option
                v-for="item in encryptionType"
                :key="item.value"
                :label="$t(`DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.encryptionType.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SecurityText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.Password')+'：'" prop="configContent.wifi.password">
        <el-row>
          <el-col :span="6">
            <el-input
              size="small"
              v-model="dictData.configContent.wifi.password"
              disabled
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.PasswordText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.Agent')+'：'" prop="configContent.wifi.proxyType">
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              disabled
              v-model="dictData.configContent.wifi.proxyType"
            >
              <el-option
                v-for="item in proxyType"
                :key="item.value"
                :label="$t(`DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.proxyType.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.proxySpanText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- 自动 -->
      <el-form-item
        label="ProxyPACURL："
        prop="configContent.wifi.proxyPacUrl"
        :required="true"
        v-if="dictData.configContent.wifi.proxyType == 'Auto'"
      >
        <el-row>
          <el-col :span="6">
            <el-input
              size="small"
              v-model="dictData.configContent.wifi.proxyPacUrl"
              disabled
            ></el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- 手动 -->
      <el-form-item
      :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.ServerAddress')+'：'"
        prop="configContent.wifi.proxyServer"
        :required="true"
        v-if="dictData.configContent.wifi.proxyType == 'Manual'"
      >
        <el-row>
          <el-col :span="6">
            <el-input
              size="small"
              v-model="dictData.configContent.wifi.proxyServer"
              disabled
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanTextAddress')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
      :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.Port')+'：'"
        prop="configContent.wifi.proxyServerPort"
        :required="true"
        v-if="dictData.configContent.wifi.proxyType == 'Manual'"
      >
        <el-row>
          <el-col :span="6">
            <el-input
              size="small"
              v-model="dictData.configContent.wifi.proxyServerPort"
              disabled
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanTextPort')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
      :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.ProxyUsername')+'：'"
        prop="configContent.wifi.proxyUserName"
        v-if="dictData.configContent.wifi.proxyType == 'Manual'"
      >
        <el-row>
          <el-col :span="6">
            <el-input
              size="small"
              v-model="dictData.configContent.wifi.proxyUserName"
              disabled
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanTextName')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
      :label="$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.proxyPassword')+'：'"
        prop="configContent.wifi.proxyPassword"
        v-if="dictData.configContent.wifi.proxyType == 'Manual'"
      >
        <el-row>
          <el-col :span="6">
            <el-input
              size="small"
              v-model="dictData.configContent.wifi.proxyPassword"
              disabled
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.WiFiSetup.SpanTextPassword')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      wifiConnType: [
        {
          value: "0",
          label: "SSID",
        },
        {
          value: "1",
          label: "BSSID",
        },
      ],
      encryptionType: [
        {
          value: "None",
          label: "无",
        },
        {
          value: "WPA",
          label: "WPA / WPA2",
        },
        {
          value: "WEP",
          label: "WEP",
        },
      ],
      proxyType: [
        {
          value: "None",
          label: "无",
        },
        {
          value: "Manual",
          label: "手动",
        },
        {
          value: "Auto",
          label: "自动",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.dictData.configContent.wifi.password = "";
    this.dictData.configContent.wifi.proxyPassword = "";
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
}
.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  /* line-height: 24px; */
  color: #807e7e;
  display: block;
}
::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}
::v-deep .el-radio__input.is-disabled + span.el-radio__label {
  color: #807e7e;
}
::v-deep .el-form-item__content {
  font-size: 12px;
}
</style>