<template>
  <div>
    <!-- 分配历史 -->
    <el-row :gutter="5" class="mt15" style="display: flex">
      <el-col :span="3">
        <el-date-picker :clearable="false"
          style="width: 100%"
          v-model="queryObj.startTime"
          type="date"
          :placeholder="$t('Delivery.enforcementresult.SelectAStartTime')"
          size="small"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions0"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
   <span style="line-height: 30px; color: #dcdfe6">~</span>
</el-col>
      <el-col :span="3">
        <el-date-picker :clearable="false"
          style="width: 100%"
          v-model="queryObj.endTime"
          type="date"
          :placeholder="$t('Delivery.enforcementresult.SelectAEndTime')"
          size="small"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions1"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" size="small" @click="search('ser')">{{
          $t("public.Inquire")
        }}</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="16" style="padding: 10px 0 0 10px">
      <el-col :span="4">
        <el-button
          style="
            border-radius: 3px 0px 0px 3px;
            margin: 0px 0px 0px 0px;
            margin-left: 1px;
          "
          @click="delArr(delarr)"
          :type="typeInfo"
          :disabled="!hasSeleted"
          size="small"
          >{{ $t("public.Delete") }}</el-button
        >
        <el-button
          type="primary"
          @click="deleteAll"
          class="delbtn"
          size="small"
          >{{ $t("Delivery.distributionManagement.deleteAll") }}</el-button
        >
      </el-col>
    </el-row>
    <el-row :gutter="16" class="mt16">
      <div style="position: relative">
        <!-- 列表 -->
        <el-table
          ref="tableData"
          :row-key="getRowKeys"
          :data="tableData"
          lazy
          size="mini"
          stripe
          tooltip-effect="dark"
          class="sort_table"
          style="width: 100%"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          :default-sort="{ prop: 'createTime', order: 'descending' }"
          @selection-change="delobj"
          @sort-change="sort"
        >
          <el-table-column
            type="selection"
            align="center"
            width="60"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            width="180"
            prop="deliveryName"
            :label="$t('Delivery.DistributionHistory.AssignName')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <el-link
                @click="nameDetail(row)"
                type="primary"
                style="color: #d38a08; font-size: 12px"
              >
                {{ row.deliveryName }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            prop="mapType"
            :label="$t('Delivery.DistributionHistory.ConcreteType')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <!-- 移动应用 -->
              <span v-if="row.mapType == 11">{{
                $t("Delivery.distributionManagement.MobileApp")
              }}</span>
              <!-- 通知内容 -->
              <span v-if="row.mapType == 6">{{
                $t("Delivery.distributionManagement.NotContent")
              }}</span>
              <!-- 合规策略 -->
              <span v-if="row.mapType == 9">{{
                $t("Delivery.distributionManagement.CompStrategy")
              }}</span>
              <!-- 设备策略 -->
              <span v-if="row.mapType == 8">{{
                $t("Delivery.distributionManagement.devicePolicy")
              }}</span>
              <!-- 文档内容 -->
              <span v-if="row.mapType == 10">{{
                $t("Delivery.distributionManagement.docContent")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="300"
            prop="toList"
            :label="$t('Delivery.DistributionHistory.AllocationObject')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <el-link
                v-for="(item, index) in row.toList"
                :key="index"
                type="primary"
                style="color: #d38a08; font-size: 12px"
              >
              
                <!-- 部门 -->
                <span v-if="item.name == '2'" @click="backDetail(row, 2)"
                  >{{ $t("public.Section") }}[{{ item.value }}]&nbsp;</span
                >
                <!-- 用户组 -->
                <span v-if="item.name == '3'" @click="backDetail(row, 3)"
                  >{{ $t("Delivery.DistributionHistory.UserGroup") }}[{{
                    item.value
                  }}]&nbsp;</span
                >
                <!-- 用户 -->
                <span v-if="item.name == '4'" @click="backDetail(row, 4)"
                  >{{ $t("Delivery.DistributionHistory.User") }}[{{
                    item.value
                  }}]&nbsp;</span
                >
                <!-- 设备组 -->
                <span v-if="item.name == '5'" @click="backDetail(row, 5)"
                  >{{ $t("Delivery.DistributionHistory.DeviceGroup") }}[{{
                    item.value
                  }}]&nbsp;</span
                >
                <!-- 设备 -->
                <span v-if="item.name == '6'" @click="backDetail(row, 6)"
                  >{{ $t("Delivery.DistributionHistory.Device") }}[{{
                    item.value
                  }}]</span
                >
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            :label="$t('Delivery.DistributionHistory.AllocateTime')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              {{ row.createTime | dateformats }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <pagination
        :page="currentPage"
        :limit="pageSize"
        :total="total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </el-row>
    <!-- 名称弹框 - 通知内容 -->
    <Eldialog
      :width="width"
      @handleClose="handleClose"
      :title="$t('Delivery.DistributionHistory.Details')"
      :dialogVisible="noticeVisible"
      :flagbtn="false"
    >
      <noticeDetail :dictData="notice" />
    </Eldialog>
    <!-- 名称弹框 - 移动应用 -->
    <Eldialog
      :width="'455px'"
      @handleClose="handleClose"
      :title="$t('Delivery.DistributionHistory.Details')"
      :dialogVisible="entAppVisible"
      :flagbtn="false"
    >
      <entAppDetail :dictData="entApp" />
    </Eldialog>
    <!-- 名称弹框 - 文档内容 -->
    <Eldialog
      :width="'455px'"
      @handleClose="handleClose"
      :title="$t('Delivery.DistributionHistory.Details')"
      :dialogVisible="documentVisible"
      :flagbtn="false"
    >
      <documentDetail :dictData="document" />
    </Eldialog>
    <!-- 名称弹框 - 设备策略IOS -->
    <Eldialog
      :width="'1295px'"
      :num="'600px'"
      @handleClose="handleClose"
      :title="$t('Delivery.DistributionHistory.Details')"
      :dialogVisible="policyIOSVisible"
      :flagbtn="false"
    >
      <policyIOS :dictData="policyIOS" />
    </Eldialog>
    <!-- 名称弹框 - 设备策略Android -->
    <Eldialog
      :width="'1295px'"
      :num="'600px'"
      @handleClose="handleClose"
      :title="$t('Delivery.DistributionHistory.Details')"
      :dialogVisible="policyAndroidVisible"
      :flagbtn="false"
    >
      <policyAndroid :dictData="policyAndroid" />
    </Eldialog>
    <!-- 名称弹框 - 合规策略Android -->
    <Eldialog
      :width="'1095px'"
      :num="'560px'"
      @handleClose="handleClose"
      :title="$t('Delivery.DistributionHistory.Details')"
      :dialogVisible="clientAndroidVisible"
      :flagbtn="false"
    >
      <clientAndroid :dictData="clientAndroid" />
    </Eldialog>
    <!-- 名称弹框 - 合规策略IOS -->
    <Eldialog
      :width="'1095px'"
      :num="'560px'"
      @handleClose="handleClose"
      :title="$t('Delivery.DistributionHistory.Details')"
      :dialogVisible="clientIOSVisible"
      :flagbtn="false"
    >
      <clientIOS :dictData="clientIOS" />
    </Eldialog>
    <!-- 分配对象弹框 -->
    <Eldialog
      :width="'615px'"
      :num="'469px'"
      @handleClose="handleClose"
      :title="$t('Delivery.DistributionHistory.AllocationObject')"
      :dialogVisible="deliveryVisible"
      :flagbtn="false"
    >
      <deliveryDetail :dictData="delivery" :read="read" />
    </Eldialog>
    <!-- 保存成功的弹框 -->
    <dialog-info
      :dialogInfo="Successdialog"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="seccess"
      :width="'240px'"
    />
  </div>
</template>

<script>
import Nav from "@/components/hearder";
import pagination from "@/components/pagination/page.vue";
import Eldialog from "@/components/elDialog/index.vue";
import dialogInfo from "@/components/promptMessage/index.vue";
import noticeDetail from "./components/noticeDetail.vue";
import entAppDetail from "./components/entAppDetail.vue";
import documentDetail from "./components/documentDetail.vue";
import policyAndroid from "./components/policyAndroid.vue";
import policyIOS from "./components/policyIOS.vue";
import clientAndroid from "./components/clientAndroid.vue";
import clientIOS from "./components/clientIOS.vue";
import deliveryDetail from "./components/deliveryDetail.vue";
export default {
  components: {
    Nav,
    pagination,
    Eldialog,
    dialogInfo,
    noticeDetail,
    entAppDetail,
    documentDetail,
    policyAndroid,
    policyIOS,
    clientAndroid,
    clientIOS,
    deliveryDetail,
  },

  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      read: "",
      seccess: "",
      width: "455px",
      orderColume: "createTime",
      orderRule: "DESC",
      currentPage: 1,
      pageSize: 10,
      total: 0,
      flag: 1,
      entAppVisible: false,
      noticeVisible: false,
      documentVisible: false,
      policyIOSVisible: false,
      policyAndroidVisible: false,
      Successdialog: false,
      deliveryVisible: false,
      clientAndroidVisible: false,
      clientIOSVisible: false,
      delarr: [],
      tableData: [],
      queryObj: {
        startTime: "",
        endTime: "",
      },
      pickerOptions1: {},
      pickerOptions0: {},
      notice: {},
      entApp: {},
      document: {},
      policyIOS: {},
      policyAndroid: {},
      clientAndroid: {},
      clientIOS: {},
      delivery: {
        deviceData: [],
        groupData: [],
        userData: [],
        userGroupData: [], // V.6.1.3版本新加用户组
        deviceGroupData: [], // V.6.1.3版本新加设备组
      },
    };
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },
  mounted() {
    this.getDatalist();
  },

  methods: {
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    handleClose() {
      this.read = "";
      this.Successdialog = false;
      this.noticeVisible = false;
      this.entAppVisible = false;
      this.documentVisible = false;
      this.policyAndroidVisible = false;
      this.policyIOSVisible = false;
      this.clientIOSVisible = false;
      this.clientAndroidVisible = false;
      this.deliveryVisible = false;
    },
    determine() {
      this.Successdialog = false;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用查询方法
      this.search();
    },
    // 日期
    changeDate() {
      let start = new Date(this.queryObj.startTime).getTime();
      let end = new Date(this.queryObj.endTime).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
    },
    // 列表
    async getDatalist() {
      //一个月前日期
      var getAnyMonth = function (symbol, n) {
        var symbol = symbol || "-";
        var nowDate = new Date();
        nowDate.setMonth(nowDate.getMonth() + n);
        var year = nowDate.getFullYear();
        var month = nowDate.getMonth() + 1;
        var day = nowDate.getDate();
        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;
        return year + symbol + month + symbol + day;
      }; //当前日期
      var getDate = function () {
        var date = new Date();
        var year = date.getFullYear();
        var mon = date.getMonth() + 1;
        var day = date.getDate();
        return (
          year +
          "-" +
          (mon < 10 ? "0" + mon : mon) +
          "-" +
          (day < 10 ? "0" + day : day)
        );
      };
      this.queryObj.startTime = getAnyMonth("-", -1);
      this.queryObj.endTime = getDate();
      this.changeDate();
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        start: this.queryObj.startTime,
        end: this.queryObj.endTime,
      };
      const res = await this.$axios.post(
        "/httpServe/delivery/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 查询
    async search(flag) {
      if (flag == "ser") {
        this.currentPage = 1;
      }
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        start: this.queryObj.startTime,
        end: this.queryObj.endTime,
      };
      const res = await this.$axios.post(
        "/httpServe/delivery/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 全部删除
    deleteAll() {
      var param = { ids: [] };
      this.$confirm(
        this.$t("Delivery.enforcementresult.CheckDelAll"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/delivery/clear",
            param,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.getDatalist();
        })
        .catch(() => {
          return false;
        });
    },
    // 选中数组
    delobj(val) {
      this.delarr = val;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },
    // 删除
    delArr(val) {
      var ids = [];
      val.forEach((item) => {
        ids.push(item.deliveryId);
      });
      var param = { ids: ids };
      this.$confirm(
        this.$t("Delivery.enforcementresult.CheckDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/delivery/clear",
            param,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.tableData.clearSelection();
          this.getDatalist();
        })
        .catch(() => {
          return false;
        });
    },
    // 名称弹框
    nameDetail(val) {
      if (val.mapType == 6) {
        // 通知内容
        var parma = {
          ids: [val.mapId],
        };
        this.$axios
          .post("/httpServe/notice/selectOne", parma, true)
          .then((res) => {
            this.notice = res.data;
            this.noticeVisible = true;
          });
      } else if (val.mapType == 11) {
        // 移动应用
        var parma = {
          id: val.mapId,
        };
        this.$axios
          .post("/httpServe/entApp/selectOne", parma, true)
          .then((res) => {
            this.entApp = res.data;
            this.entAppVisible = true;
          });
      } else if (val.mapType == 10) {
        // 文档内容
        var parma = {
          ids: [Number(val.mapId)],
        };
        this.$axios
          .post("/httpServe/document/selectOne", parma, true)
          .then((res) => {
            this.document = res.data;
            this.documentVisible = true;
          });
      } else if (val.mapType == 9) {
        // 合规策略
        if (val.scope == 20) {
          // Android
          var parma = {
            id: val.mapId,
          };
          this.$axios
            .post("/httpServe/policy/selectOne", parma, true)
            .then((res) => {
              this.clientAndroid = res.data;
              this.clientAndroidVisible = true;
            });
        } else {
          // ios
          var parma = {
            id: val.mapId,
          };
          this.$axios
            .post("/httpServe/policy/selectOne", parma, true)
            .then((res) => {
              this.clientIOS = res.data;
              this.clientIOSVisible = true;
            });
        }
      } else if (val.mapType == 8) {
        // 设备策略
        // ios
        if (val.scope == 10) {
          var parma = {
            id: val.mapId,
          };
          this.$axios
            .post("/httpServe/policy/selectOne", parma, true)
            .then((res) => {
              this.policyIOS = res.data;
              this.policyIOSVisible = true;
            });
        } else {
          // Android
          var parma = {
            id: val.mapId,
          };
          this.$axios
            .post("/httpServe/policy/selectOne", parma, true)
            .then((res) => {
              this.policyAndroid = res.data;
              this.policyAndroidVisible = true;
            });
        }
      }
    },
    // 分配对象弹框
    async backDetail(row, val) {
      this.read = val + "";
      this.getDeviceData(row.deliveryId);
      this.getUserData(row.deliveryId);
      this.getGroupData(row.deliveryId);
      // V.6.1.3版本新加用户组和设备组
      this.getUserGroupData(row.deliveryId);
      this.getDeviceGroupData(row.deliveryId);
      this.deliveryVisible = true;
    },
    // 设备
    async getDeviceData(deliveryId) {
      var parma = {
        deliveryId: deliveryId,
      };
      const res = await this.$axios.post(
        "/httpServe/delivery/getDeviceDataInfo",
        parma,
        true
      );
      this.delivery.deviceData = res.data;
    },
    // 用户
    async getUserData(deliveryId) {
      var parma = {
        deliveryId: deliveryId,
      };
      const res = await this.$axios.post(
        "/httpServe/delivery/getUserDataInfo",
        parma,
        true
      );
      this.delivery.userData = res.data;
    },
    // 部门
    async getGroupData(deliveryId) {
      var parma = {
        deliveryId: deliveryId,
      };
      const res = await this.$axios.post(
        "/httpServe/delivery/getGroupDataInfo",
        parma,
        true
      );
      this.delivery.groupData = res.data;
    },
    // V.6.1.3版本新加用户组
    async getUserGroupData(deliveryId) {
      console.log('V.6.1.3版本新加用户组');
      var parma = {
        deliveryId: deliveryId,
      };
      const res = await this.$axios.post(
        "/httpServe/delivery/getUserGroupDataInfo",
        parma,
        true
      );
      this.delivery.userGroupData = res.data;
    },
    // V.6.1.3版本新加设备组
    async getDeviceGroupData(deliveryId) {
      var parma = {
        deliveryId: deliveryId,
      };
      const res = await this.$axios.post(
        "/httpServe/delivery/getDeviceGroupDataInfo",
        parma,
        true
      );
      this.delivery.deviceGroupData = res.data;
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },
    //当前页全选
    curSelection() {
      this.$refs.tableData.toggleAllSelection();
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.tableData.toggleRowSelection(row);
        });
      } else {
        this.$refs.tableData.clearSelection();
      }
      this.isCheckShow = true;
    },
    // 排序
    sort(column) {
      if (column.order === "ascending") {
        this.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      this.search();
    },
  },
};
</script>

<style lang='scss' scoped>
.mt15 {
  padding: 10px 0 0 10px;
}

.mt16 {
  padding: 10px 18px 0 18px;
}

.el-button + .el-button {
  margin-left: 1px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
