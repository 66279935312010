<template>
  <div class="password">
    <el-radio-group v-model="appWhite.use" @change="changeEvent" disabled>
      <el-radio :label="'1'">{{ $t("public.Enable") }}</el-radio>
      <el-radio :label="'0'">{{ $t("public.Forbidden") }}</el-radio>
    </el-radio-group>
    <el-form :model="dataList" label-width="150px">
      <el-form-item
        :label="
          $t('DeviceManage.DevicePolicy.IOSPolicy.networkAppBW.ListType') + '：'
        "
      >
        <el-row>
          <el-col :span="6">
            <el-select
              size="small"
              style="width: 205px"
              disabled
              @change="changeSelect"
              v-model="appWhite.bwType"
            >
              <el-option
                v-for="item in bwType"
                :key="item.value"
                :label="
                  $t(
                    `DeviceManage.DevicePolicy.IOSPolicy.networkAppBW.bwType.${item.label}`
                  )
                "
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="
          $t(
            'DeviceManage.DevicePolicy.IOSPolicy.networkAppBW.ApplicationList'
          ) + '：'
        "
        :required="true"
      >
        <div style="display: flex">
          <div style="width: 500px; height: 218px; overflow: auto">
            <el-table
              :data="appWhite.list"
              lazy
              size="mini"
              stripe
              class="tableClass"
              style="width: 500px"
              :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
            >
              <el-table-column
                width="200"
                prop="name"
                :label="$t('public.ApplicationName')"
              >
              </el-table-column>
              <el-table-column
                width="200"
                prop="identifier"
                :label="$t('public.ApplicationID')"
              >
              </el-table-column>
              <el-table-column
                header-align="left"
                align="left"
                :label="$t('public.Controls')"
                show-overflow-tooltip
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Eldialog from "@/components/elDialog";
import pagination from "@/components/pagination/page.vue";
export default {
  components: {
    Eldialog,
    pagination,
  },
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      appWhite: {
        use: "0",
        bwType: "1",
        list: [],
      },
      dataQuery: {
        currentPage: 1,
        pageSize: 10,
        totle: 0,
      },
      bw: {
        use: "0",
        bwType: "0",
        blackWhiteList: [],
      },
      inputText: "",
      dataForm: [],
      multipleSelection: [],
      geograpFlag: false,
      bwType: [
        {
          value: "1",
          label: "白名单",
        },
      ],
      flagStatus: false,
    };
  },
  created() {
    if (this.dataList.id) {
      this.appWhite = this.dataList.configContent.appWhite;
      if (!this.appWhite.list) {
        this.$set(this.appWhite, "list", []);
      }
      // if(this.dataList.configContent.appWhite.use == "0"){
      //   this.appWhite.list = []
      // }
      if (this.dataList.configContent.appWhite.bwType == undefined) {
        this.appWhite.bwType = "1";
      }
    }
  },
  mounted() {},
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    handleParentGetLists(page, limit) {
      this.dataQuery.currentPage = page;
      this.dataQuery.pageSize = limit;
      // 调用查询方法
      this.application();
    },
    handleClose() {
      this.geograpFlag = false;
      this.inputText = "";
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = [...val];
    },
    deterGeograp() {
      this.flagStatus = true;
      this.multipleSelection.forEach((item, index) => {
        this.appWhite.list.push({
          id: item.id,
          name: item.name,
          identifier: item.identifier,
        });
      });
      var result = [];
      var obj = {};
      for (var i = 0; i < this.appWhite.list.length; i++) {
        if (!obj[this.appWhite.list[i].name]) {
          result.push(this.appWhite.list[i]);
          obj[this.appWhite.list[i].name] = true;
        }
      }
      this.appWhite.list = result;
      this.$emit("submitSave", {
        appWhite: this.appWhite,
      });
      this.geograpFlag = false;
      this.inputText = "";
    },
    async application() {
      this.geograpFlag = true;
      var param = {
        searchName: this.inputText,
        osType: "10",
        currentPage: this.dataQuery.currentPage,
        pageSize: this.dataQuery.pageSize,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/ios/appLib/getDataInfo",
        param,
        true
      );
      this.dataQuery.totle = res.data.total;
      this.dataForm = res.data.content;
      this.$nextTick(() => {
        this.dataForm.forEach((item, index) => {
          this.appWhite.list.forEach((i, j) => {
            if (item.id == i.id) {
              this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        });
      });
    },
    //查询
    searcInstall() {
      this.dataQuery.currentPage = 1;
      this.application();
    },
    changeEvent() {
      this.$emit("submitSave", {
        appWhite: this.appWhite,
      });
    },
    changeSelect() {
      this.$emit("submitSave", {
        appWhite: this.appWhite,
      });
    },
    deleteGeograp(val) {
      this.appWhite.list.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.appWhite.list.splice(i, 1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
}
::v-deep .el-table__empty-block {
  width: 34% !important;
}
::v-deep .el-form-item__content {
  line-height: 0px;
  margin-top: 5px;
}
</style>
