<template>
  <div class="password">
    <el-radio-group v-model="dictData.configContent.wifibw.use" disabled>
      <el-radio :label="'1'">{{ $t('public.Enable') }}</el-radio>
      <el-radio :label="'0'">{{ $t('public.Forbidden') }}</el-radio>
    </el-radio-group>
    <el-form :model="dictData" ref="dictData" :rules="rules" label-width="150px">
      <el-form-item :label="$t('DeviceManage.ClientPolicy.WifiBlackWhiteList.ListType')" prop="configContent.wifibw.type" v-if="dictData.configContent.wifibw.use == 0">
        <el-row>
          <el-col :span="6">
            <el-select size="small" disabled v-model="dictData.configContent.wifibw.type">
              <el-option v-for="item in bwType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.ClientPolicy.WifiBlackWhiteList.ConnType')" prop="configContent.wifibw.connType" v-if="dictData.configContent.wifibw.use == 0">
        <el-row>
          <el-col :span="6">
            <el-select size="small" disabled v-model="dictData.configContent.wifibw.connType">
              <el-option v-for="item in connType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.ClientPolicy.WifiBlackWhiteList.WifiList')" prop="configContent.wifibw.macStr">
        <el-row>
          <el-col :span="6">
            <el-input style="margin-top: 5px" size="small" v-model="dictData.configContent.wifibw.macStr"
              v-if="dictData.configContent.wifibw.connType == '1'" type="textarea" :rows="4" disabled></el-input>
            <el-input style="margin-top: 5px" size="small" v-model="dictData.configContent.wifibw.ssidStr"
              v-if="dictData.configContent.wifibw.connType == '0' || dictData.configContent.wifibw.use == 0"
              type="textarea" :rows="4" disabled></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint" v-if="dictData.configContent.wifibw.connType == '1'">{{ $t('DeviceManage.ClientPolicy.WifiBlackWhiteList.Message2') }}</span>
            <span class="hint">{{ $t('DeviceManage.ClientPolicy.WifiBlackWhiteList.Message1') }}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.ClientPolicy.TriggeringCondition')">
        <span v-if="dictData.configContent.wifibw.type == 1">{{ $t('DeviceManage.ClientPolicy.WifiBlackWhiteList.Message4') }}</span>
        <span v-if="dictData.configContent.wifibw.type == 0 ||
          dictData.configContent.wifibw.use == '0'
          ">{{ $t('DeviceManage.ClientPolicy.WifiBlackWhiteList.Message3') }}</span>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.ClientPolicy.ResponseAction')" prop="configContent.wifibw.content">
        <el-table :data="dictData.configContent.wifibw.content" lazy size="mini" stripe class="sort_table"
          style="width: 501px; margin-top: 5px" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }">
          <el-table-column width="60" type="index" :label="$t('DeviceManage.ClientPolicy.SerialNumber')">
          </el-table-column>
          <el-table-column width="259" prop="name" :label="$t('DeviceManage.ClientPolicy.ResponseAction1')">
            <template v-slot="{ row }">
              <template v-if="row.operType == 0 || dictData.configContent.wifibw.use == '0'
                ">
                {{$t('DeviceManage.ClientPolicy.ResponseActionName0')}}
              </template>
              <template v-if="row.operType == 1"> {{$t('DeviceManage.ClientPolicy.ResponseActionName1')}} </template>
              <template v-if="row.operType == 2"> {{$t('DeviceManage.ClientPolicy.ResponseActionName2')}} </template>
              <template v-if="row.operType == 3"> {{$t('DeviceManage.ClientPolicy.ResponseActionName3')}} </template>
              <template v-if="row.operType == 4"> {{$t('DeviceManage.ClientPolicy.ResponseActionName4')}} </template>
              <template v-if="row.operType == 5"> {{$t('DeviceManage.ClientPolicy.ResponseActionName5')}} </template>
              <template v-if="row.operType == 6"> {{$t('DeviceManage.ClientPolicy.ResponseActionName6')}} </template>
            </template>
          </el-table-column>
          <el-table-column width="180" prop="name" :label="$t('DeviceManage.ClientPolicy.LayTime1')">
            <template v-slot="{ row }">
              <template>{{ row.frequency }}</template>
              <template>
                <span v-if="row.frequencyType == 'hour'">{{$t('DeviceManage.ClientPolicy.Hour')}}</span>
                <span v-if="row.frequencyType == 'min'">{{$t('DeviceManage.ClientPolicy.Min')}}</span>
                <span v-if="row.frequencyType == 'day'">{{$t('DeviceManage.ClientPolicy.Day')}}</span>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      checkList: [
        {
          value: 'bluetooth',
          label: '蓝牙'
        },
        {
          value: 'wifi',
          label: 'WIFI网络'
        },
        {
          value: 'dataLink',
          label: '移动网络'
        },

        {
          value: 'hotSpot',
          label: '个人热点'
        },
        {
          value: 'location',
          label: '系统定位'
        }
      ],
      bwType: [
        {
          value: '0',
          label: this.$t('DeviceManage.ClientPolicy.BlackWhiteList.BlackList')
        },
        {
          value: '1',
          label: this.$t('DeviceManage.ClientPolicy.BlackWhiteList.WhiteList')
        }
      ],
      connType: [
        {
          value: '0',
          label: 'SSID'
        },
        {
          value: '1',
          label: 'BSSID'
        }
      ],
      content: [
        {
          frequency: '0',
          frequencyType: 'min',
          operType: '0'
        }
      ],
      rules: {
        configContent: {
          wifibw: {
            type: { required: true, message: '', trigger: 'blur' },
            connType: { required: true, message: '', trigger: 'blur' },
            macStr: { required: true, message: '', trigger: 'blur' },
            content: { required: true, message: '', trigger: 'blur' }
          }
        }
      },
    }
  },
  created() {
    if (this.dictData.configContent.wifibw.use == 0) {
      this.dictData.configContent.wifibw.content = this.content
      this.dictData.configContent.wifibw.type = this.$t('DeviceManage.ClientPolicy.BlackWhiteList.BlackList')
      this.dictData.configContent.wifibw.connType = '0'
    }
  },
  mounted() {
    this.show()
  },
  methods: {
    show() {
      if (this.dictData.configContent.wifibw.use == 0) {
        this.dictData.configContent.wifibw.type = this.$t('DeviceManage.ClientPolicy.BlackWhiteList.BlackList')
        this.dictData.configContent.wifibw.connType = '0'
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}

.el-radio-group {
  margin-left: 150px;
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
}

::v-deep .el-table__empty-block {
  width: 34% !important;
}

.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  /* line-height: 24px; */
  color: #807e7e;
  display: block;
}

::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is-disabled+span.el-radio__label {
  color: #807e7e;
}

::v-deep .el-form-item__content {
  font-size: 12px;
}

::v-deep .el-table__row>td {
  border: none;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table th.is-leaf {
  border: none;
}</style>