<template>
  <div class="limit">
    <el-radio-group v-model="limit.use" @change="changeEvent" disabled>
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <div class="limitBox">
      <!-- 数据保护 -->
      <div class="protection">
        <div class="protectionWord" style="color:#827e7e;">{{$t('DeviceManage.DevicePolicy.IOSPolicy.limit.DataProtection')}}：</div>
        <div class="checkbox" style="margin-top:10px;margin-left:3px;">
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowCamera')" v-model="limit.allowCamera" true-label="1" false-label="0" disabled
            @change="changeCheck"></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowScreenSharp')" v-model="limit.allowScreenSharp" true-label="1" false-label="0"
            disabled @change="changeCheck"></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowPasscodeModification')" v-model="limit.allowPasscodeModification" true-label="1" false-label="0"
            disabled @change="changeCheck"></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowBluetoothModification')" v-model="limit.allowBluetoothModification" true-label="1" false-label="0"
            disabled @change="changeCheck"></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowCloudBackup')" v-model="limit.allowCloudBackup" true-label="1" false-label="0"
            disabled @change="changeCheck"></el-checkbox>
          <!-- <el-checkbox
                label="阻止连接到文件应用程序中的任何已连接的 USB 设备 *"
                v-model="limit.allowFilesUSBDriveAccess"
                true-label="1"
                false-label="0"
                disabled
                @change="changeCheck"
              ></el-checkbox> -->
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowFilesUSBDriveAccess')" v-model="limit.allowFilesUSBDriveAccess" true-label="1" false-label="0"
              disabled @change="changeCheck"></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowSafari')" v-model="limit.allowSafari" true-label="1" false-label="0"
            disabled @change="changeCheck"></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.safariAllowAutoFill')" v-model="limit.safariAllowAutoFill" true-label="1" false-label="0"
            disabled @change="changeCheck"></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowPasswordAutoFill')" v-model="limit.allowPasswordAutoFill" true-label="1" false-label="0"
            disabled @change="changeCheck"></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowHostPairing')" v-model="limit.allowHostPairing" true-label="1" false-label="0"
            disabled @change="changeCheck"></el-checkbox>
        </div>
      </div>
      <!-- 系统设置 -->
      <div class="setting">
        <div class="settingWord" style="color:#807e7e;">{{$t('DeviceManage.DevicePolicy.IOSPolicy.limit.SystemSettings')}}：</div>

        <div class="checkbox" style="margin-top:10px;">
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowApplication')" v-model="limit.allowApplication" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowAppRemoval')" v-model="limit.allowAppRemoval" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowVideoConferencing')" v-model="limit.allowVideoConferencing" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowEraseContentAndSettings')" v-model="limit.allowEraseContentAndSettings" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowAccountModification')" v-model="limit.allowAccountModification" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowDeviceNameModification')" v-model="limit.allowDeviceNameModification" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowKeyboardShortcuts')" v-model="limit.allowKeyboardShortcuts" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowNotificationsModification')" v-model="limit.allowNotificationsModification" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowDictation')" v-model="limit.allowDictation" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowFingerprintModification')" v-model="limit.allowFingerprintModification" true-label="1"
            false-label="0" @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowNFC')" v-model="limit.allowNFC" true-label="1" false-label="0" @change="changeCheck"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.forceDelayedSoftwareUpdates')" v-model="limit.forceDelayedSoftwareUpdates" true-label="1" false-label="0"
            @change="changeCheck" disabled style="margin-top: 0px;margin-bottom: 0px;">
            {{$t('Delivery.DistributionHistory.forceDelayedSoftwareUpdates')}}
            <el-input style="width:50px;margin-left:4px;height:20px;" v-show="limit.forceDelayedSoftwareUpdates == '1'"
              v-model.trim="limit.enforcedSoftwareUpdateDelay" size="mini"
              disabled maxlength=2></el-input>
            <el-button type="text" v-show="limit.forceDelayedSoftwareUpdates == '1'"
              style="display:inline-block;margin-left:4px;" disabled>{{$t('DeviceManage.DevicePolicy.IOSPolicy.limit.Day')}}</el-button>
            *
          </el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowUIConfigurationProfileInstallation')" v-model="limit.allowUIConfigurationProfileInstallation" true-label="1"
            style="margin-top: -10px;margin-bottom: 0;" false-label="0" @change="changeCheck"
            disabled></el-checkbox>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowAssistant')" v-model="limit.allowAssistant" true-label="1"
            style="margin-top: -10px;margin-bottom: 0;" false-label="0" @change="changeCheck"
            disabled></el-checkbox>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowAutomaticAppDownloads')" v-model="limit.allowAutomaticAppDownloads" true-label="1"
            style="margin-top: -10px;margin-bottom: 0;" false-label="0" @change="changeCheck"
            disabled></el-checkbox>
        </div>
      </div>
      <!-- 网络设置 -->
      <div class="setting">
        <div class="settingWord" style="color:#807e7e;">{{$t('DeviceManage.DevicePolicy.IOSPolicy.limit.NetworkSettings')}}：</div>
        <div class="checkbox" style="margin-top:10px;">
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowAirDrop')" v-model="limit.allowAirDrop" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowAppCellularDataModification')" v-model="limit.allowAppCellularDataModification" true-label="1"
            false-label="0" @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowPersonalHotspotModification')" v-model="limit.allowPersonalHotspotModification" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.allowVPNCreation')" v-model="limit.allowVPNCreation" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.IOSPolicy.limit.forceWiFiPowerOn')" v-model="limit.forceWiFiPowerOn" true-label="1" false-label="0"
            @change="changeCheck" disabled></el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      limit: {
        use: "0",
        allowCamera: "0",
        allowScreenSharp: "0",
        allowAppRemoval: "0",
        allowVideoConferencing: "0",
        allowAirDrop: "0",
        allowEraseContentAndSettings: "0",
        allowPasscodeModification: "0",
        allowBluetoothModification: "0",
        allowAccountModification: "0",
        allowAppCellularDataModification: "0",
        allowDeviceNameModification: "0",
        allowCloudBackup: "0",
        allowKeyboardShortcuts: "0",
        allowNotificationsModification: "0",
        allowDictation: "0",
        allowFilesUSBDriveAccess: "0",
        allowFingerprintModification: "0",
        allowNFC: "0",
        safariAllowAutoFill: "0",
        allowPersonalHotspotModification: "0",
        allowVPNCreation: "0",
        forceWiFiPowerOn: "0",
        allowSafari: "0",
   
        forceDelayedSoftwareUpdates: "0",
        enforcedSoftwareUpdateDelay: 90,
        allowApplication: "0",
        allowPasswordAutoFill: "0",
        allowHostPairing: "0",
        allowUIConfigurationProfileInstallation: "0",
        allowAssistant:"0",
        allowAutomaticAppDownloads:"0",
      }
    };
  },
  created() {
    if (this.dataList.id) {
        this.limit = this.dataList.configContent.limit;
    };

  },
  mounted() { },
  methods: {
    changeEvent() {
      this.$emit("submitSave", {
        limit: this.limit
      })
    },
    changeCheck() {
      if (this.limit.forceDelayedSoftwareUpdates == 0) {
        this.limit.enforcedSoftwareUpdateDelay = 90
      }
      
      this.$emit("submitSave", {
        limit: this.limit
      })
    }
  },
};
</script>

<style lang='scss' scoped>
.limit {
  padding-top: 20px;
  width: 100%;
  height: 100%;
}

.limitBox {
  width: 100%;
  display: flex;
}

.el-radio-group {
  margin-left: 150px;
}

.protection {
  width: 420px;
  display: flex;

  .protectionWord {
    width: 150px;
    text-align: right;
    height: 24px;
    line-height: 24px;
    margin: 8px 0;
    font-size: 14px;
  }
}

.checkbox {
  width: 160px;

  .el-checkbox {
    height: 24px;
    line-height: 24px;
  }
}

.setting {
  width: 340px;
  display: flex;

  .settingWord {
    width: 70px;
    text-align: right;
    height: 24px;
    line-height: 24px;
    margin: 8px 0;
    font-size: 14px;
  }
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-checkbox__label {
  font-size: 14px;
  color: #807e7e;
}


::v-deep .el-checkbox__input.is-+span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is-+span.el-radio__label {
  color: #807e7e;
}

.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  color: #807e7e;
  display: block;
}

::v-deep .el-form-item__content {
  font-size: 12px;
}
::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
  margin-top: -10px;

}
</style>