<template>
  <div class="password">
    <el-radio-group v-model="dictData.configContent.position.use" disabled>
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <el-form :model="dictData" ref="dictData" label-width="150px">
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.Position.Frequency')+'：'" prop="configContent.position.frequency" :required="true">
        <el-row>
          <el-col :span="6">
            <el-input
              size="small"
              v-model="dictData.configContent.position.frequency"
              disabled
              style="width:240px"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.Position.FrequencyText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.Position.Number')+'：'" prop="configContent.position.number" :required="true">
        <el-row>
          <el-col :span="6">
            <el-input
              size="small"
              v-model="dictData.configContent.position.number"
              disabled
              style="width:240px"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.Position.NumberText')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
     
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.password {
  padding-top: 20px;
}
.el-radio-group {
  margin-left: 150px;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
}
.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  /* line-height: 24px; */
  color: #807e7e;
  display: block;
}
::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}
::v-deep .el-radio__input.is-disabled + span.el-radio__label {
  color: #807e7e;
}
::v-deep .el-form-item__content {
  font-size: 12px;
}
</style>