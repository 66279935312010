<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" class="tabs">
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.BasicInformation')" name="1">
        <basicInformation :key="timer1" :dictData="dictData" v-if="activeName == '1'" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.Equipment')" name="2">
        <root :key="timer2" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.SimChange')" name="3">
        <sim :key="timer3" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.SdChange')" name="4">
        <sd :key="timer4" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.ViolationOutreach')" name="5">
        <violation :key="timer5" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.Geographical')" name="6">
        <geofence :key="timer6" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.SystemVersion')" name="7">
        <version :key="timer7" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.TrafficAnomaly')" name="8">
        <netflow :key="timer8" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.DeviceBattery')" name="9">
        <battery :key="timer9" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.StorageSpace')" name="10">
        <capacity :key="timer10" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.ApplicationInstall')" name="11">
        <install :key="timer11" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.SystemFunction')" name="12">
        <systemFunctions :key="timer12" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.BlackWhiteList')" name="13">
        <bw :key="timer13" :dictData="dictData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.WifiBlackWhiteList')" name="14">
        <wifibw :key="timer14" :dictData="dictData" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import basicInformation from "./clientAndroid/basicInformation.vue";
import capacity from "./clientAndroid/capacity.vue";
import root from "./clientAndroid/root.vue";
import geofence from "./clientAndroid/geofence.vue";
import version from "./clientAndroid/version.vue";
import battery from "./clientAndroid/battery.vue";
import systemFunctions from "./clientAndroid/systemFunctions.vue";
import sim from "./clientAndroid/sim.vue";
import sd from "./clientAndroid/sd.vue";
import violation from "./clientAndroid/violation.vue";
import netflow from "./clientAndroid/netflow.vue";
import install from "./clientAndroid/install.vue";
import bw from "./clientAndroid/bw.vue";
import wifibw from "./clientAndroid/wifibw.vue";
export default {
  components: {
    basicInformation,
    capacity,
    root,
    geofence,
    version,
    battery,
    systemFunctions,
    sim,
    sd,
    violation,
    netflow,
    install,
    bw,
    wifibw,
  },
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      activeName: "1",
      timer1: "",
      timer2: "",
      timer3: "",
      timer4: "",
      timer5: "",
      timer6: "",
      timer7: "",
      timer8: "",
      timer9: "",
      timer10: "",
      timer11: "",
      timer12: "",
      timer13: "",
      timer14: "",
    };
  },
  watch: {
    dictData: function (newVal, oldVal) {
      if (this.dictData) {
        this.activeName = "1";
      }
    }
  },
  created() { },
  mounted() { },
  methods: {
    handleLoad(data) {
      let name = data.name;
      if (name == 1) {
        this.timer1 = new Date().getTime();
      } else if (name == 2) {
        this.timer2 = new Date().getTime();
      } else if (name == 3) {
        this.timer3 = new Date().getTime();
      } else if (name == 4) {
        this.timer4 = new Date().getTime();
      } else if (name == 5) {
        this.timer5 = new Date().getTime();
      } else if (name == 6) {
        this.timer6 = new Date().getTime();
      } else if (name == 7) {
        this.timer7 = new Date().getTime();
      } else if (name == 8) {
        this.timer8 = new Date().getTime();
      } else if (name == 9) {
        this.timer9 = new Date().getTime();
      } else if (name == 10) {
        this.timer10 = new Date().getTime();
      } else if (name == 11) {
        this.timer11 = new Date().getTime();
      } else if (name == 12) {
        this.timer12 = new Date().getTime();
      } else if (name == 13) {
        this.timer13 = new Date().getTime();
      } else if (name == 14) {
        this.timer14 = new Date().getTime();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.el-form-item {
  margin-bottom: 0px;
}

.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
  height: 540px;
}

::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}

::v-deep .el-tabs__item {
  padding: 0 9px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}

::v-deep .el-tabs__item.is-active {
  color: #000;
}

::v-deep .el-tabs__item:hover {
  color: #000;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}

::v-deep .el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: 8px;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
  padding-left: 6px;
}

::v-deep .el-checkbox {
  margin-right: 20px;
}

::v-deep .el-table__empty-block {
  width: 34% !important;
}

.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  color: #807e7e;
  display: block;
}

::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is-disabled+span.el-radio__label {
  color: #807e7e;
}

::v-deep .el-form-item__content {
  font-size: 12px;
}
</style>