<template>
  <div>
    <!-- 分配对象 -->
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleLoad"
      class="tabs"
    >
      <el-tab-pane :label="$t('public.Section')" name="1">
        <groups :key="timer1" :dictData="dictData.groupData" />
      </el-tab-pane>
      <!-- V.6.1.3版本新加用户组 -->
      <el-tab-pane
        :label="$t('Delivery.DistributionHistory.UserGroup')"
        name="2"
      >
        <userGroup :key="timer2" :dictData="dictData.userGroupData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('Delivery.DistributionHistory.User')" name="3">
        <users :key="timer3" :dictData="dictData.userData" />
      </el-tab-pane>
      <!-- V.6.1.3版本新加设备组 -->
      <el-tab-pane
        :label="$t('Delivery.DistributionHistory.DeviceGroup')"
        name="4"
      >
        <deviceGroup :key="timer5" :dictData="dictData.deviceGroupData" />
      </el-tab-pane>
      <el-tab-pane :label="$t('Delivery.DistributionHistory.Device')" name="5">
        <device :key="timer4" :dictData="dictData.deviceData" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import groups from "./deliveryDetail/groups.vue";
import users from "./deliveryDetail/users.vue";
import device from "./deliveryDetail/device.vue";//分配对象-设备
import userGroup from "./deliveryDetail/userGroup.vue"; // V.6.1.3版本新加用户组
import deviceGroup from "./deliveryDetail/deviceGroup.vue"; // V.6.1.3版本新加设备组
export default {
  components: {
    groups,
    users,
    device,
    userGroup,
    deviceGroup,
  },

  props: {
    dictData: {
      type: Object,
      default: {},
    },

    read: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      activeName: "",
      timer1: "",
      timer2: "",
      timer3: "",
      timer4: "",
      timer5: "",
      deviceData: [],
      groupData: [],
      userData: [],
    };
  },

  watch: {
    read: {
      handler: function (newVal, oldVal) {
        console.log(newVal, oldVal, "****");
        this.read = newVal;
        this.tabs();
      },
      deep: true,
    },
  },

  created() {
    if (this.read) {
      console.log(this.read, "00000");
      if (this.read == 2) {
        //部门tab高亮
        this.activeName = "1";
      } else if (this.read == 3) {
        //用户组tab高亮
        this.activeName = "2";
      } else if (this.read == 4) {
        //用户tab高亮
        this.activeName = "3";
      }else if (this.read == 5) {
        //设备组tab高亮
        this.activeName = "4";
      } else if (this.read == 6) {
        //设备tab高亮
        this.activeName = "5";
      } 
    }
  },

  mounted() {},

  methods: {
    tabs() {
      if (this.read == 2) {
        //部门tab高亮
        this.activeName = "1";
      } else if (this.read == 3) {
        //用户组tab高亮
        this.activeName = "2";
      } else if (this.read == 4) {
        //用户tab高亮
        this.activeName = "3";
      }else if (this.read == 5) {
        //设备组tab高亮
        this.activeName = "4";
      } else if (this.read == 6) {
        //设备tab高亮
        this.activeName = "5";
      } 
    },
    handleLoad(data) {
      console.log(data.name, "data.name");
      let name = data.name;
      if (name == 1) {
        this.timer1 = new Date().getTime();
      } else if (name == 2) {
        this.timer2 = new Date().getTime();
      } else if (name == 3) {
        this.timer4 = new Date().getTime();
      } else if (name == 4) {
        this.timer5 = new Date().getTime();
      } else if (name == 5) {
        this.timer6 = new Date().getTime();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.el-form-item {
  margin-bottom: 0px;
}

.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px 0px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
  height: 450px;
}

::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}

::v-deep .el-tabs__item {
  padding: 0 20px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}

::v-deep .el-tabs__item.is-active {
  color: #000;
}

::v-deep .el-tabs__item:hover {
  color: #000;
}

// ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
//   border-bottom-color: #fff;
//   border-top: 2px solid #30373d;
// }

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
</style>