<template>
    <div style="width:100%;height: 400px;overflow: hidden;overflow: auto;">
      <!-- 设备组 -->
      <el-table :data="dictData" lazy size="mini" stripe class="sort_table" style="width: 540px; margin-top: 5px; margin-left: 10px" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :default-sort="{ prop: 'deviceName', order: 'descending' }">
        <el-table-column width="180" prop="name" :label="$t('Delivery.DistributionHistory.DeviceGroupName')" :show-overflow-tooltip="true">
        </el-table-column>
        <!-- <el-table-column width="130" prop="loginName" :label="$t('public.Username')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column width="129" prop="userName" :label="$t('public.Name')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column width="129" prop="groupFullName" :label="$t('public.Section')" :show-overflow-tooltip="true">
        </el-table-column> -->
      </el-table>
      <div style="margin-left: 10px; margin-top: 5px">
        {{$t('AccessConfiguration.ServiceResourceManagement.Total')}} <span class="pl">{{ dictData.length }}</span> {{$t('AccessConfiguration.ServiceResourceManagement.Records')}}
      </div>
    </div>
  </template>
  <script>
  export default {
    props: {
      dictData: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        deviceData: {},
      }
    },
    created() {},
    mounted() {},
    methods: {
     
    }
  }
  </script>
  
  <style lang='scss' scoped>
  * {
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: Microsoft YaHei;
    font-size: 12px;
  }
  .pl {
    color: #000;
    line-height: 22px;
    font-weight: bold;
  }
  ::v-deep .el-table__empty-block {
    width: 20% !important;
  }
  .hint {
    font-size: 12px;
    margin-left: 8px;
    height: 24px;
    color: #807e7e;
    display: block;
  }
  ::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
    color: #807e7e;
    line-height: 24px;
    margin: 10px 0;
  }
  ::v-deep .el-radio__input.is-disabled + span.el-radio__label {
    color: #807e7e;
  }
  ::v-deep .el-form-item__content {
    font-size: 12px;
  }
  ::v-deep .el-table__row > td {
    border: none;
  }
  ::v-deep .el-table::before {
    height: 0px;
  }
  ::v-deep .el-table th.is-leaf {
    border: none;
  }
  </style>
  