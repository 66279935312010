<template>
  <div class="limit">
    <el-radio-group v-model="dictData.configContent.limit.use" disabled>
      <el-radio :label="'1'">{{$t('public.Enable')}}</el-radio>
      <el-radio :label="'0'">{{$t('public.Forbidden')}}</el-radio>
    </el-radio-group>
    <div class="limitBox">
      <!-- 数据保护 -->
      <div class="protection">
        <div class="protectionWord">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.DataProtection')+'：'}}</div>
        <div class="checkbox">
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowBluetooth')" v-model="dictData.configContent.limit.allowBluetooth" true-label="1" false-label="0"
            disabled></el-checkbox>
          <template>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowCamera')" v-model="dictData.configContent.limit.allowCamera" true-label="1" false-label="0"
            disabled></el-checkbox>
            <!-- v6.1.2新增 -->
            <div style="display: flex;width:500px;margin-left:25px;"  v-if="limituse" disabled>
              <div style="
                  width: 100px;
                  height: 28px;
                  border: 1px solid #ccc;
                  border-radius: 4px;text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                " size="small" prop="name">
                <el-popover placement="bottom" width="300" trigger="hover" append-to-body>
                  <el-button disabled style="margin: 3px;" type="primary" size="mini"
                    v-for="(item, index) in dictData.configContent.limit.allowCameraList" :key="index">{{ item.name}}
                    <!-- <i class="el-icon-delete el-icon--right" disabled></i> -->
                    </el-button>
                  <!-- <el-button size="mini" disabled>全部删除</el-button> -->
                  <template slot="reference">
                    <div>
                      <span
                        style="font-size: 12px; background: #f1f1f1; padding: 3px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;cursor: pointer; "
                        v-for="(item, index) in dictData.configContent.limit.allowCameraList" :key="index" :show-overflow-tooltip="true">{{
                        item.name }}</span>
                    </div>
                  </template>
                </el-popover>
              </div>
              <!-- <el-button disabled type="primary" size="small" style="margin-left: 5px; padding: 6px 3px"
                icon="el-icon-plus" v-if="limituse" >例外应用</el-button> -->
            </div>
          </template>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowScreenshots')" v-model="dictData.configContent.limit.allowScreenshots" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowRecord')" v-model="dictData.configContent.limit.allowRecord" true-label="1" false-label="0"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSharefile')" v-model="dictData.configContent.limit.allowSharefile" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowADBPort')" v-model="dictData.configContent.limit.allowADBPort" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowUSB')" v-model="dictData.configContent.limit.allowUSB" true-label="1" false-label="0"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowPreBrowser')" v-model="dictData.configContent.limit.allowPreBrowser" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSDWrite')" v-model="dictData.configContent.limit.allowSDWrite" true-label="1"
            false-label="0" disabled></el-checkbox>
            <!-- v6.1.2 新增 -->
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowBackup')" v-model="dictData.configContent.limit.allowBackup" true-label="1" false-label="0" @change="limits"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowOtgStorage')" v-model="dictData.configContent.limit.allowOtgStorage" true-label="1" false-label="0" @change="limits"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowMtp')" v-model="dictData.configContent.limit.allowMtp" true-label="1" false-label="0" @change="limits"
            disabled></el-checkbox>
        </div>
      </div>
      <!-- 系统设置 -->
      <div class="setting">
        <div class="settingWord">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.SystemSettings')+'：'}}</div>
        <div class="checkbox">
          <template>
            <el-checkbox
            :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowLocation')"
              v-model="allowLocationStatus"
              true-label="1"
              false-label="0"
              @change="allowLocationLimits"
              disabled
            >
            </el-checkbox>
            <el-radio-group @change="elRadioChange"  v-if="limitLocation" v-model="dictData.configContent.limit.allowLocation" style="margin-left: 24px">
              <el-radio :label="'1'" style="line-height: 2;" disabled >{{$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowed')}}</el-radio>
              <el-radio :label="'2'" style="line-height: 2;" disabled >{{$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.forceOn')}}</el-radio>
            </el-radio-group>
          </template>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowNFC')" v-model="dictData.configContent.limit.allowNFC" true-label="1" false-label="0"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowInfraRed')" v-model="dictData.configContent.limit.allowInfraRed" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowAddAccount')" v-model="dictData.configContent.limit.allowAddAccount" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowRestoreSettings')" v-model="dictData.configContent.limit.allowRestoreSettings" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowDeveloper')" v-model="dictData.configContent.limit.allowDeveloper" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowUpdateDate')" v-model="dictData.configContent.limit.allowUpdateDate" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowAirplaneMode')" v-model="dictData.configContent.limit.allowAirplaneMode" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowDropDownMenu')" v-model="dictData.configContent.limit.allowDropDownMenu" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSafeMode')" v-model="dictData.configContent.limit.allowSafeMode" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowTurnOff')" v-model="dictData.configContent.limit.allowTurnOff" true-label="1" false-label="0"
            disabled></el-checkbox>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSysUpdate')" v-model="dictData.configContent.limit.allowSysUpdate" true-label="1" false-label="0" @change="limits"
            disabled></el-checkbox>
        </div>
      </div>
      <!-- 网络设置 -->
      <div class="setting">
        <div class="settingWord">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.NetworkSettings')+'：'}}</div>
        <div class="checkbox">
          <!-- v6.1.2新增 -->
          <template>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowWifi')" v-model="allowWifis" true-label="1" false-label="0"
              disabled></el-checkbox>
            <el-radio-group v-model="dictData.configContent.limit.allowWifi" style="margin-left: 24px" v-if="limitWifi">
              <el-radio :label="'1'" style="line-height: 2" disabled>{{$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowed')}}</el-radio>
              <el-radio :label="'2'" style="line-height: 2" disabled>{{$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.forceOn')}}</el-radio>
            </el-radio-group>
          </template>
          <!-- <el-checkbox label="禁用WIFI网络" v-model="dictData.configContent.limit.allowWifi" true-label="1" false-label="0"
            disabled></el-checkbox> -->
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowWifiSet')" v-model="dictData.configContent.limit.allowWifiSet" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowHotspot')" v-model="dictData.configContent.limit.allowHotspot" true-label="1" false-label="0"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowWIFIDirect')" v-model="dictData.configContent.limit.allowWIFIDirect" true-label="1"
            false-label="0" disabled></el-checkbox>
            <!-- v6.1.2新增 -->
          <template>
            <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowDatalinks')" v-model="allowDatalinks" true-label="1" false-label="0"
               disabled></el-checkbox>
            <el-radio-group @change="elDataChange" v-model="dictData.configContent.limit.allowDatalink" style="margin-left: 24px" v-if="limitDatalink">
              <el-radio :label="'1'" style="line-height: 2" disabled>{{$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowed')}}</el-radio>
              <el-radio :label="'2'" style="line-height: 2" disabled>{{$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.forceOn')}}</el-radio>
            </el-radio-group>
          </template>
          <!-- <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowDatalink')" v-model="dictData.configContent.limit.allowDatalink" true-label="1"
            false-label="0" disabled></el-checkbox> -->
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowAPN')" v-model="dictData.configContent.limit.allowAPN" true-label="1" false-label="0"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowVPN')" v-model="dictData.configContent.limit.allowVPN" true-label="1" false-label="0"
            disabled></el-checkbox>
            <!-- v6.1.2 新增 -->
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowBluetoothShareNet')" v-model="dictData.configContent.limit.allowBluetoothShareNet" true-label="1" false-label="0"
            @change="limits" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowUsbShareNet')" v-model="dictData.configContent.limit.allowUsbShareNet" true-label="1" false-label="0"
            @change="limits" disabled></el-checkbox>
        </div>
      </div>
      <!-- 通讯设置 -->
      <div class="setting">
        <div class="settingWord">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.Communication')+'：'}}</div>
        <div class="checkbox">
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowPhone')" v-model="dictData.configContent.limit.allowPhone" true-label="1" false-label="0"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowMakeCall')" v-model="dictData.configContent.limit.allowMakeCall" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowInboundCall')" v-model="dictData.configContent.limit.allowInboundCall" true-label="1"
            false-label="0" disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowNote')" v-model="dictData.configContent.limit.allowNote" true-label="1" false-label="0"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSendSMS')" v-model="dictData.configContent.limit.allowSendSMS" true-label="1" false-label="0"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowAccSMS')" v-model="dictData.configContent.limit.allowAccSMS" true-label="1" false-label="0"
            disabled></el-checkbox>
          <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.limit.allowSecondSIM')" v-model="dictData.configContent.limit.allowSecondSIM" true-label="1"
            false-label="0" disabled></el-checkbox>
        </div>
      </div>
      <!-- 从上海银行2024新增 迁移到 v6.3.2-->
      <!-- 应用设置 -->
      <div class="setting">
        <div class="settingWord" style="color: #807e7e">应用设置：</div>
        <div class="checkbox">
          <template>
            <el-checkbox label="禁止卸载应用 *" v-model="dictData.configContent.limit.allowUninstallApp" true-label="1" false-label="0"
              disabled></el-checkbox>
            <div style="display: flex;width:500px;margin-left:25px;"  v-if="UninstallUse">
              <div style="
                  width: 100px;
                  height: 28px;
                  border: 1px solid #ccc;
                  border-radius: 4px;text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                " size="small" prop="name">
              <!--    element plus 的popover组件如何去自适应位置
              :fallback-placements="['bottom', 'top', 'right', 'left']"当 Popover 弹出框的位置接近边界时，应该自动切换到相反的方向进行显示
              popper-class添加最大高度，超过出现滚动条
              -->
                <el-popover placement="bottom" width="300" popper-class="max-h-300px overflow-auto" :fallback-placements="['bottom', 'top', 'right', 'left']"  trigger="hover" append-to-body>
                  <el-button style="margin: 3px;" type="primary" size="mini"
                    v-for="(item, index) in dictData.configContent.limit.allowUninstallAppList" :key="index">{{ item.name}}（{{item.identifier}}）<i></i></el-button>
                  <template slot="reference">
                    <div>
                      <span
                        style="font-size: 12px; background: #f1f1f1; padding: 3px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;cursor: pointer; "
                        v-for="(item, index) in dictData.configContent.limit.allowUninstallAppList" :key="index" :show-overflow-tooltip="true">{{
                        item.name }}（{{item.identifier}}）</span>
                    </div>
                  </template>
                </el-popover>
              </div>
              <!-- <el-button @click="selectApps" type="primary" size="small" style="margin-left: 5px; padding: 6px 3px"
                icon="el-icon-plus" v-if="UninstallUse" disabled>选择应用</el-button> -->
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      allowLocationStatus: '0',
      allowWifis: '0',//wifi网络
      allowDatalinks: '0',//移动网络
      limituse:false,
      limitLocation:false,// 定位服务
      limitWifi:false,// wifi网络
      limitDatalink:false,// 移动网络
      limit:{
        allowCameraList: [],
      }
    };
  },
  created() {
    if(this.dictData.id&&this.dictData.configContent.limit.use == 1) {
    //  this.limit = this.dictData.configContent.limit
     if(this.dictData.configContent.limit.allowLocation=='0'){
      this.allowLocationStatus='0'
      this.limitLocation = false;
     }else{
      this.limitLocation = true;
      this.allowLocationStatus='1'
     }
     if(this.dictData.configContent.limit.allowCamera=='0'){
      this.limituse=false
     }else{
      this.limituse=true
     }
     if(this.dictData.configContent.limit.allowLocation=='0'){
      this.limitLocation=false
     }else{
      this.limitLocation=true
     }
     if(this.dictData.configContent.limit.allowLocation=='0'){
      this.limitLocation=false
     }else{
      this.limitLocation=true
     }
     if(this.dictData.configContent.limit.allowWifi=='0'){
      this.allowWifis="0"
      this.limitWifi=false
     }else{
      this.limitWifi=true
      this.allowWifis="1"
     }
     if(this.dictData.configContent.limit.allowDatalink=='0'){
      this.allowDatalinks="0"
      this.limitDatalink=false
     }else{
      this.limitDatalink=true
      this.allowDatalinks="1"
     }
     if(this.dictData.configContent.limit.allowUninstallApp=='0'){
      this.UninstallUse=false
     }else{
      this.UninstallUse=true
     }
    }
  },
  mounted() {},
  methods: {
    limits(item){
      this.$emit("submitSave",{
        limit:this.limit
      })
    },
    allowLocationLimits(item){
      if(item==='1'){
        this.limit.allowLocation='1'
        this.limitLocation = true;
      }else if(item==='0'){
        this.limitLocation = false;
        this.limit.allowLocation='0'
        this.allowLocationStatus='0'
      }
      this.$emit("submitSave",{
        limit:this.limit
      })

    },
    changeEvent(){
      this.$emit("submitSave",{
        limit:this.limit
      })
    },
    elRadioChange(item){
      if(item==='2'){
        this.allowLocationStatus='1'
        this.limit.allowLocation='2'
      }else if(item==='1'){
        this.limit.allowLocation='1'
        this.allowLocationStatus='1'
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.limit {
  padding-top: 20px;
  width: 100%;
  height: 100%;
}

.limitBox {
  width: 100%;
  display: flex;
}

.el-radio-group {
  margin-left: 150px;
}

.protection {
  width: 310px;
  display: flex;

  .protectionWord {
    width: 150px;
    text-align: right;
    height: 24px;
    line-height: 24px;
    margin: 8px 0;
    font-size: 12px;
  }
}

.checkbox {
  width: 160px;

  .el-checkbox {
    height: 24px;
    line-height: 24px;
  }
}

.setting {
  width: 300px;
  display: flex;

  .settingWord {
    width: 70px;
    text-align: right;
    height: 24px;
    line-height: 24px;
    margin: 8px 0;
    font-size: 12px;
  }
}

::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-checkbox__label {
  font-size: 12px;
  color: #807e7e;
}

::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}

::v-deep .el-radio__input.is-disabled+span.el-radio__label {
  color: #807e7e;
}

.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  /* line-height: 24px; */
  color: #807e7e;
  display: block;
}

::v-deep .el-form-item__content {
  font-size: 12px;
}

::v-deep .el-table__row>td {
  border: none;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table th.is-leaf {
  border: none;
}
</style>