<template>
  <div class="basic">
    <el-form :model="dictData" ref="dictData" label-width="150px">
      <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.BasicInformation.PolicyName')+'：'" prop="name" :required="true">
        <el-row>
          <el-col :span="6">
            <el-input size="small" v-model="dictData.name" disabled></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.IOSPolicy.BasicInformation.MessagePort')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      checkList: [],
      time: [],
      checked1: false,
      checked2: false,
      timeFlag: false,
      geofenceFlag: false,
      policiesFlag: false,
    };
  },
  created() {},
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang='scss' scoped>
.basic {
  padding-top: 20px;
}
.el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
  padding-left: 6px;
}
::v-deep .el-checkbox {
  margin-right: 20px;
}
.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  /* line-height: 24px; */
  color: #807e7e;
  display: block;
}
::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}
::v-deep .el-radio__input.is-disabled + span.el-radio__label {
  color: #807e7e;
}
::v-deep .el-form-item__content {
  font-size: 12px;
}
</style>