<template>
  <div class="basic">
    <el-form :model="dictData" ref="dictData" label-width="150px">
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.PolicyName')+'：'" prop="name" :required="true">
        <el-row>
          <el-col :span="6">
            <el-input size="small" v-model="dictData.name" disabled></el-input>
          </el-col>
          <el-col :span="8">
            <span class="hint">{{$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.MessagePort')}}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.Restrictions')+'：'" prop="configContent.condition">
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFence')" v-model="time[0]" disabled></el-checkbox>
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFence')" v-model="time[1]" disabled></el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.TimeFence')+'：'" prop="configContent.times" :required="true" v-show="timeFlag">
        <el-table :data="dictData.configContent.times" lazy size="mini" stripe class="sort_table" style="width: 300px" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }">
          <el-table-column width="199" prop="name" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.Name')">
          </el-table-column>
          <el-table-column :label="$t('public.Controls')" width="99"> </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.GeoFence')+'：'" prop="configContent.geofences" :required="true" v-show="geofenceFlag">
        <el-table :data="dictData.configContent.geofences" lazy size="mini" stripe class="sort_table" style="width: 300px; margin-top: 6px" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }">
          <el-table-column width="199" prop="name" :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.Name')">
          </el-table-column>
          <el-table-column :label="$t('public.Controls')" width="99"> </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.Applicables')+'：'" prop="configContent.devicePolicies" :required="true" v-show="policiesFlag">
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.limit')" v-model="dictData.configContent.devicePolicies.limit" true-label="1" false-label="0" disabled></el-checkbox>
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.bw')" v-model="dictData.configContent.devicePolicies.bw" true-label="1" false-label="0" disabled></el-checkbox>
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.wifibw')" v-model="dictData.configContent.devicePolicies.wifibw" true-label="1" false-label="0" disabled></el-checkbox>
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.networkAccessBW')" v-model="dictData.configContent.devicePolicies.networkAccessBW" true-label="1" false-label="0" disabled></el-checkbox>
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.blueToothBW')" v-model="dictData.configContent.devicePolicies.blueToothBW" true-label="1" false-label="0" disabled></el-checkbox>
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.networkAppBW')" v-model="dictData.configContent.devicePolicies.networkAppBW" true-label="1" false-label="0" disabled></el-checkbox>
        <el-checkbox :label="$t('DeviceManage.DevicePolicy.AndroidPolicy.BasicInformation.recentsBW')" v-model="dictData.configContent.devicePolicies.recentsBW" true-label="1" false-label="0" disabled></el-checkbox>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      checkList: [],
      time: [],
      checked1: false,
      checked2: false,
      timeFlag: false,
      geofenceFlag: false,
      policiesFlag: false
    }
  },
  watch: {
    dictData: function (newVal, oldVal) {
      console.log(newVal, oldVal)
      this.dictData.configContent.condition = newVal.configContent.condition
      this.dictData.configContent.times = newVal.configContent.times
      this.dictData.configContent.geofences = newVal.configContent.geofences

      this.time = this.dictData.configContent.condition.split(',')

      if (this.dictData.configContent.condition == '') {
        this.$set(this.time, 0, false)
        this.$set(this.time, 1, false)
        this.timeFlag = false
        this.geofenceFlag = false
        this.policiesFlag = false
      } else if (
        this.dictData.configContent.condition.includes('time') &&
        !this.dictData.configContent.condition.includes('geofence')
      ) {
        this.$set(this.time, 0, true)
        this.$set(this.time, 1, false)
        this.policiesFlag = true
        this.geofenceFlag = false
        this.timeFlag = true
      } else if (
        this.dictData.configContent.condition.includes('geofence') &&
        !this.dictData.configContent.condition.includes('time')
      ) {
        this.$set(this.time, 0, false)
        this.$set(this.time, 1, true)
        this.geofenceFlag = true
        this.policiesFlag = true
        this.timeFlag = false
      } else if (
        this.dictData.configContent.condition.includes('geofence') &&
        this.dictData.configContent.condition.includes('time')
      ) {
        this.$set(this.time, 0, false)
        this.$set(this.time, 1, true)
        this.geofenceFlag = true
        this.timeFlag = true
        this.policiesFlag = true
      }
    }
  },
  created() {},
  mounted() {
    this.condition()
  },
  methods: {
    condition() {
      this.time = this.dictData.configContent.condition.split(',')

      if (this.dictData.configContent.condition == '') {
        this.$set(this.time, 0, false)
        this.$set(this.time, 1, false)
      } else if (
        this.dictData.configContent.condition.includes('time') &&
        !this.dictData.configContent.condition.includes('geofence')
      ) {
        this.$set(this.time, 0, true)
        this.$set(this.time, 1, false)
        this.policiesFlag = true
        this.timeFlag = true
      } else if (
        this.dictData.configContent.condition.includes('geofence') &&
        !this.dictData.configContent.condition.includes('time')
      ) {
        this.$set(this.time, 0, false)
        this.$set(this.time, 1, true)
        this.geofenceFlag = true
        this.policiesFlag = true
      } else if (
        this.dictData.configContent.condition.includes('geofence') &&
        this.dictData.configContent.condition.includes('time')
      ) {
        this.$set(this.time, 0, false)
        this.$set(this.time, 1, true)
        this.geofenceFlag = true
        this.timeFlag = true
        this.policiesFlag = true
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.basic {
  padding-top: 20px;
}
.el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-checkbox__label {
  font-size: 12px;
  padding-left: 6px;
}
::v-deep .el-checkbox {
  margin-right: 20px;
}
.hint {
  font-size: 12px;
  margin-left: 8px;
  height: 24px;
  color: #807e7e;
  display: block;
}
::v-deep .el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #807e7e;
  line-height: 24px;
  margin: 10px 0;
}
::v-deep .el-radio__input.is-disabled + span.el-radio__label {
  color: #807e7e;
}
::v-deep .el-form-item__content {
  font-size: 12px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
