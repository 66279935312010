<template>
  <div>
    <el-form :model="dictData" ref="dictData" label-width="150px">
      <el-form-item :label="$t('public.ApplicationName')+'：'" prop="appName">
        {{ dictData.appName }}
      </el-form-item>
      <el-form-item :label="$t('public.OperatingSystem')+'：'" prop="osTypeId">
        <template v-if="dictData.osTypeId == 20"> Android </template>
        <template v-if="dictData.osTypeId == 10"> iOS </template>
        <template v-if="dictData.osTypeId == 30"> Windows </template>
        <template v-if="dictData.osTypeId == 40"> Linux </template>
      </el-form-item>
      <el-form-item :label="$t('public.Size')+'：'" prop="appSizeStr">
        {{ dictData.appSizeStr }}
      </el-form-item>
      <el-form-item :label="$t('public.Version')+'：'" prop="versionName">
        {{ dictData.versionName }}
      </el-form-item>
      <el-form-item label="MD5：" prop="md5">
        {{ dictData.md5 }}
      </el-form-item>
      <el-form-item :label="$t('Delivery.DistributionHistory.AppCategory')+'：'" prop="appcategoryid">
        {{ dictData.appcategoryid }}
      </el-form-item>
      <el-form-item :label="$t('Delivery.DistributionHistory.Memo')+'：'" prop="memo">
        <el-input
          type="textarea"
          :rows="4"
          v-model="dictData.memo"
          disabled
          size="mini"
          style="width: 90%; margin-top: 10px"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.el-form-item {
  margin-bottom: 0px;
}
</style>