import { render, staticRenderFns } from "./smsBW.vue?vue&type=template&id=86fd4140&scoped=true&"
import script from "./smsBW.vue?vue&type=script&lang=js&"
export * from "./smsBW.vue?vue&type=script&lang=js&"
import style0 from "./smsBW.vue?vue&type=style&index=0&id=86fd4140&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86fd4140",
  null
  
)

export default component.exports